import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from '@reach/router'
import { RootState, Dispatch } from 'src/store'
import { FlexRouter, AuthRoute } from 'src/routes'
import ConnectRoutes from 'src/connect/routes'

type ConnectProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const Connect: React.FC<ConnectProps> = ({ settings, setSettingsState }) => {
  React.useEffect(() => {
    setSettingsState({ showCentreEnvironment: true })
  }, [setSettingsState])

  return (
    // relative to /connect/*
    <>
      <FlexRouter>
        <AuthRoute
          path="/:env/*"
          as={ConnectRoutes}
          settings={settings}
          setSettingsState={setSettingsState}
        />
        <AuthRoute
          default
          as={ConnectDefault}
          settings={settings}
          setSettingsState={setSettingsState}
        />
      </FlexRouter>
    </>
  )
}

const mapState = ({ settings }: RootState) => ({ settings })
const mapDispatch = ({ settings }: Dispatch) => ({ setSettingsState: settings.setState })

export default connect(mapState, mapDispatch)(Connect)

///////////////////////////////////////////////////////////////////////////////

const VALID_ENVIRONMENTS = ['production', 'uat']
const validEnvironment = (env: string) => VALID_ENVIRONMENTS.includes(env)

///////////////////////////////////////////////////////////////////////////////

interface ConnectDefaultProps {
  env?: string // route param
  settings: RootState['settings']
  setSettingsState: Dispatch['settings']['setState']
}

const ConnectDefault: React.FC<ConnectDefaultProps> = ({ env, settings, setSettingsState }) => {
  let { centreAPIEndpoint } = settings

  React.useEffect(() => {
    if (env && env !== centreAPIEndpoint) {
      setSettingsState({ centreAPIEndpoint: env })
    }
  }, [env, centreAPIEndpoint, setSettingsState])

  if (env && !validEnvironment(env)) {
    return <Redirect noThrow to={`/connect/${centreAPIEndpoint || 'production'}/customers`} />
  }

  return <Redirect noThrow to={`/connect/${centreAPIEndpoint || 'production'}/customers`} />
}
