import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link } from '@reach/router'
import { copyRoute, latestConfigRoute, translatorEnvironmentUrl } from 'src/translators/utils'
import {
  STATUS,
  deleteTranslatorConfig,
  getTranslatorDeployments,
  getTranslatorConfigHistory,
  getLatestTranslatorConfigForEnvironment,
} from 'src/utils/api'
import { CONFIG } from 'src/utils/config'
import {
  Box,
  Column,
  Row,
  Button,
  ButtonLink,
  Card,
  CardContent,
  CopyIcon,
  IconButton,
  Link as NativeLink,
  Table2,
  useBlockTable,
  Tooltip,
  DeleteIcon,
  RepeatClockIcon,
} from 'src/ui'

const Placeholder = ({ children, ...rest }) => (
  <Row color="rgba(0, 0, 0, 0.54)" {...rest}>
    <em>{children}</em>
  </Row>
)

const OverflownText = (text: string) => {
  const ref = useRef(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    const element = ref.current!
    setIsOverflown(element.scrollWidth > element.clientWidth)
  }, [])

  return (
    <Tooltip label={text} isDisabled={!isOverflown} closeDelay={500}>
      <Box position="relative" isTruncated ref={ref}>
        {text || '-'}
      </Box>
    </Tooltip>
  )
}

interface ConfigHistoryListProps {
  configHistory: {
    status: STATUS
    data: TranslatorConfig[]
  }
  company_slug: string
  environment_slug: string
  translator_id: string
}

const ConfigHistoryList: FC<ConfigHistoryListProps> = ({
  configHistory,
  company_slug,
  environment_slug,
  translator_id,
}) => {
  let columns = React.useMemo(
    () => [
      {
        id: 'created_at',
        accessor: 'created_at',
        Header: 'Created At',
        width: '20%',
        Cell: ({ row }) => {
          return new Date(row.original.created_at).toLocaleString()
        },
      },
      {
        id: 'created_by',
        accessor: 'created_by',
        Header: 'Creator',
        width: '15%',
      },
      {
        id: 'adapter_version',
        accessor: 'adapter_version',
        Header: 'Adapter Version',
        width: '20%',
        Cell: ({ row }) => {
          return OverflownText(`${row.original.adapter_module} - ${row.original.adapter_version}`)
        },
      },
      {
        id: 'revision_comment',
        accessor: 'revision_comment',
        Header: 'Comment',
        width: '20%',
        Cell: ({ row }) => {
          return OverflownText(row.original.revision_comment)
        },
      },
      {
        id: 'prototype',
        accessor: 'prototype',
        Header: 'Prototype',
        width: '10%',
        Cell: ({ row }) => {
          return (
            <Row width="100%" justifyContent="center">
              {row.original['prototype'] ? '✔' : '-'}
            </Row>
          )
        },
      },
      {
        id: '_actions',
        Header: '',
        width: '10%',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Tooltip label="Copy Config" aria-label="Copy Config" placement="left">
              <IconButton
                as={Link}
                {...{
                  to: copyRoute({
                    company_slug,
                    environment_slug,
                    translator_id,
                    copy_id: row.original.id,
                  }),
                }}
                icon={<CopyIcon />}
                aria-label="Copy"
                isRound
                variant="ghost"
              />
            </Tooltip>
          )
        },
      },
    ],
    [company_slug, environment_slug, translator_id]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useBlockTable({
    data: configHistory.data || [],

    tableOptions: {
      initialState: {
        sortBy: [{ id: 'created_at', desc: true }],
      },
    },

    columns,
  })

  return (
    <Column mb={4}>
      <Row fontWeight="bold" py={3}>
        Config History
      </Row>

      {configHistory.status === STATUS.loaded && (
        <>
          <Table2.Block
            {...getTableProps()}
            className="table"
            css={{
              '&': {
                boxShadow: 'none',
              },
              '.tbody': {
                maxHeight: 540,
                overflow: 'auto',
              },
            }}
          >
            <Table2.BlockHeader headerGroups={headerGroups} />
            <Table2.BlockBody
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2.Block>

          {configHistory.data.length === 0 && (
            <Placeholder justifyContent="center" fontSize="sm" py={3}>
              No config history yet
            </Placeholder>
          )}
        </>
      )}

      {configHistory.status === STATUS.failed && (
        <Placeholder>Problem loading config history</Placeholder>
      )}

      {configHistory.status === STATUS.loading && <Placeholder>Loading...</Placeholder>}
    </Column>
  )
}

///////////////////////////////////////////////////////////////////////////////

interface DeploymentListProps {
  deployments: {
    status: STATUS
    data: Deployment[]
  }
  refreshDeployments: () => void
}

const DeploymentList: FC<DeploymentListProps> = ({ deployments, refreshDeployments }) => {
  let columns = React.useMemo(
    () => [
      {
        id: 'created_at',
        accessor: 'created_at',
        Header: 'Created At',
        width: '25%',
        Cell: ({ row }) => {
          return new Date(row.original.created_at).toLocaleString()
        },
      },
      {
        id: 'image_name',
        accessor: 'image_name',
        Header: 'Image Version',
        width: '50%',
        Cell: ({ row }) => (
          <>
            {row.original.image_name} - {row.original.image_version}
          </>
        ),
      },
      {
        id: 'status',
        accessor: 'status',
        Header: 'Status',
        width: '25%',
        Cell: ({ row }) => (
          <>
            {row.original.status} {row.original.status_message}
          </>
        ),
      },
    ],
    []
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useBlockTable({
    data: deployments.data || [],

    tableOptions: {
      initialState: {
        sortBy: [{ id: 'created_at', desc: true }],
      },
    },

    columns,
  })

  return (
    <Column mb={4}>
      <Row justifyContent="space-between" alignItems="center" py={3}>
        <Box fontWeight="bold">Deployment History</Box>

        <Box>
          <Tooltip label="Refresh History" aria-label="Refresh History" placement="left">
            <IconButton
              icon={<RepeatClockIcon />}
              variant="ghost"
              color="gray.600"
              isLoading={STATUS.reloading === deployments.status}
              aria-label="Refresh"
              onClick={refreshDeployments}
            />
          </Tooltip>
        </Box>
      </Row>

      {[STATUS.loaded, STATUS.reloading].includes(deployments.status) && (
        <>
          <Table2.Block
            {...getTableProps()}
            className="table"
            css={{
              '&': {
                boxShadow: 'none',
              },
              '.tbody': {
                maxHeight: 540,
                overflow: 'auto',
              },
            }}
          >
            <Table2.BlockHeader headerGroups={headerGroups} />
            <Table2.BlockBody
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2.Block>

          {deployments.data.length === 0 && (
            <Placeholder justifyContent="center" py={3}>
              No deployments yet
            </Placeholder>
          )}
        </>
      )}

      {deployments.status === STATUS.failed && (
        <Placeholder>Problem loading deployments</Placeholder>
      )}

      {deployments.status === STATUS.loading && <Placeholder>Loading...</Placeholder>}
    </Column>
  )
}

///////////////////////////////////////////////////////////////////////////////

interface TranslatorEnvironmentProps {
  environment: Environment
  translator: Translator
  showStopDialog: (args: { translator: Translator; onConfirm: () => void }) => void
  showResetDialog: (args: {
    environment_slug: string
    status: STATUS
    translator: Translator
    translatorConfig: TranslatorConfig
  }) => void
}

interface DeploymentsState {
  status: STATUS
  data: Deployment[]
}
interface ConfigHistoryState {
  status: STATUS
  data: TranslatorConfig[]
}
interface LatestConfigState {
  status: STATUS
  data: TranslatorConfig
}

function TranslatorEnvironment({
  environment,
  translator,
  showStopDialog,
  showResetDialog,
}: TranslatorEnvironmentProps) {
  const [deployments, setDeployments] = useState<DeploymentsState>({
    status: STATUS.idle,
    data: [],
  })
  const [configHistory, setConfigHistory] = useState<ConfigHistoryState>({
    status: STATUS.idle,
    data: [],
  })
  const [latestConfig, setLatestConfig] = useState<LatestConfigState>({
    status: STATUS.idle,
    data: null,
  })

  const loadConfigHistory = useCallback(async () => {
    let [err, response] = await getTranslatorConfigHistory({
      translator_id: translator.id,
      environment: environment.slug,
    })

    if (err) {
      console.error('failed to load deployments', err)

      setConfigHistory({
        status: STATUS.failed,
        data: [],
      })
      return
    }

    setConfigHistory({ status: STATUS.loaded, data: response.data.data })
  }, [environment.slug, translator.id])

  const loadDeployments = useCallback(async () => {
    let [err, response] = await getTranslatorDeployments({
      translator_id: translator.id,
      limit: 5,
    })

    if (err) {
      console.error('failed to load deployments', err)

      setDeployments({
        status: STATUS.failed,
        data: [],
      })

      return
    }

    setDeployments({
      status: STATUS.loaded,
      data: response.data.data.filter((deployment) => deployment.region === CONFIG.region),
    })
  }, [translator.id])

  const refreshDeployments = () => {
    setDeployments({ ...deployments, status: STATUS.reloading })

    loadDeployments()
  }

  const deleteLatestConfig = async () => {
    const { data, status } = latestConfig

    if (status !== STATUS.loaded || !data) return

    let [err, response] = await deleteTranslatorConfig({
      config: data,
    })

    if (!err) {
      setLatestConfig({ status: STATUS.loaded, data: null })
    }

    return [err, response]
  }

  const loadLatestConfig = useCallback(async () => {
    let [err, response] = await getLatestTranslatorConfigForEnvironment({
      environment: environment.slug,
      translator_id: translator.id,
    })

    if (err) {
      if (err.response.status === 404) {
        setLatestConfig({ status: STATUS.loaded, data: null })
      }
      return
    }

    setLatestConfig({ status: STATUS.loaded, data: response.data.data })
  }, [environment.slug, translator.id])

  useEffect(() => {
    loadDeployments()
    loadConfigHistory()
    loadLatestConfig()
  }, [loadConfigHistory, loadDeployments, loadLatestConfig])

  let configRoute = latestConfigRoute({
    translator_id: translator.id,
    environment_slug: environment.slug,
    company_slug: translator.company_slug,
  })

  let translatorUrl = translatorEnvironmentUrl({
    translator_id: translator.id,
    environment_slug: environment.slug,
  })

  return (
    <Column key={environment.slug} py={3}>
      <Row justifyContent="space-between" alignItems="center" py={3}>
        <Row alignItems="center">
          <Box>{environment.name}</Box>
        </Row>

        <Row justifyContent="space-around" alignItems="center" width={380}>
          <Button
            size="sm"
            color="default"
            width="160px"
            isDisabled={!latestConfig.data}
            onClick={() =>
              showResetDialog({
                environment_slug: environment.slug,
                status: STATUS.idle,
                translator,
                translatorConfig: latestConfig.data,
              })
            }
          >
            Reset Schedule(s)
          </Button>

          <Button
            size="sm"
            colorScheme="primary"
            width="90px"
            isDisabled={!latestConfig.data}
            isLoading={latestConfig.status !== STATUS.loaded}
            onClick={() => showStopDialog({ translator, onConfirm: deleteLatestConfig })}
          >
            {latestConfig.status !== STATUS.loaded ? '' : latestConfig.data ? 'Stop' : 'Stopped'}
          </Button>

          <ButtonLink color="primary" to={configRoute}>
            Configure
          </ButtonLink>
        </Row>
      </Row>

      <Row pt={2} pb={4}>
        <Box fontSize="sm">
          <NativeLink href={translatorUrl} color="inherit" target="_blank" rel="noopener">
            {translatorUrl}
          </NativeLink>
        </Box>
      </Row>

      <ConfigHistoryList
        configHistory={configHistory}
        company_slug={translator.company_slug}
        environment_slug={environment.slug}
        translator_id={translator.id}
      />

      <DeploymentList deployments={deployments} refreshDeployments={refreshDeployments} />
    </Column>
  )
}

///////////////////////////////////////////////////////////////////////////////

interface TranslatorProps {
  translator: Translator
  environments: Environment[]
  showDeleteDialog: (t: Translator) => void
  showStopDialog: (args: { translator: Translator; onConfirm: () => void }) => void
  showResetDialog: (args: {
    environment_slug: string
    status: STATUS
    translator: Translator
    translatorConfig: TranslatorConfig
    onConfirm: () => void
  }) => void
}

const TranslatorCard: FC<TranslatorProps> = ({
  environments,
  translator,
  showDeleteDialog,
  showStopDialog,
  showResetDialog,
}) => (
  <Card mb={3}>
    <CardContent>
      <Column>
        <Row
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #eee"
          pb={3}
        >
          <Row alignItems="center">
            <Box fontSize="lg" fontWeight="bold">
              {translator.name}
            </Box>
          </Row>

          <Row alignItems="center">
            <Box fontSize="sm">
              <em>Created {new Date(translator.created_at).toLocaleDateString()}</em>
            </Box>

            <Box mx={2}>
              <Tooltip label="Delete Translator" aria-label="Delete Translator">
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete"
                  isRound
                  variant="ghost"
                  color="gray.600"
                  onClick={() => showDeleteDialog(translator)}
                />
              </Tooltip>
            </Box>
          </Row>
        </Row>

        {environments.map((environment) => (
          <TranslatorEnvironment
            key={environment.slug}
            environment={environment}
            translator={translator}
            showStopDialog={showStopDialog}
            showResetDialog={showResetDialog}
          />
        ))}
      </Column>
    </CardContent>
  </Card>
)

export default TranslatorCard
