import React, { Children, FC } from 'react'
import { Box, Row } from 'src/ui'

const spacing = (length: number, i: number) => {
  if (length <= 1) return {}

  if (i === 0) return { pr: 3 } // first, pad right
  if (length > 2 && i !== length - 1) return { pr: 3, pl: 3 } // 3+, in the midle, pad both
  if (i === length - 1) return { pl: 3 } // last, pad left

  return {}
}

interface FormRowProps {
  rowProps?: any
  minHeight?: number
}

const FormRow: FC<FormRowProps> = ({ children, minHeight = '4.25rem', rowProps = {} }) => {
  let elementCount = Children.count(children)

  return (
    <Row mb={6} minHeight={minHeight} {...rowProps}>
      {Children.map(children, (child, i) => (
        <Box
          key={i}
          w={1 / elementCount === 1 ? '100%' : `${((1 / elementCount) * 100).toFixed()}%`}
          {...spacing(elementCount, i)}
        >
          {child}
        </Box>
      ))}
    </Row>
  )
}

export default FormRow
