import React from 'react'
import { navigate, Link } from '@reach/router'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, ConfigCard, Column, FormRow, Stack, Dialog } from 'src/ui'
import * as Yup from 'yup'
import { Field, Formik, FormikProps, Form } from 'formik'
import { TextField as FormikTextField } from 'src/ui/formik'
import { IConnectUser } from 'src/api/api'
import { useConnectUserMutations } from 'src/api/queries/connect-users'
import { useToast } from 'src/utils/toast'
import { hasPermission } from 'src/utils/permissions'
import { getEnvironment } from 'src/utils'

interface ConnectUserFormProps {
  connectCustomerId: string
  connectChannelId: string
  connectUserId: string
  connectUsers: IConnectUser[]
  header: () => void
}

interface UserFormProps {
  id: number
  connect_customer_id: number
  connect_channel_id: number
  name: string
  email: string
  bushel_id: string
  _revision?: string
}

const userFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  bushel_id: Yup.string().required('Required'),
})

const textProps = {
  height: '62px',
  mb: null,
}

export const UserForm: React.FC<ConnectUserFormProps> = ({
  connectCustomerId,
  connectChannelId,
  connectUserId,
  connectUsers,
  header,
}) => {
  let { saveConnectUser, deleteConnectUser } = useConnectUserMutations()
  let toast = useToast()
  const queryClient = useQueryClient()
  let [isDeleting, setIsDeleting] = React.useState(false)
  let [isSubmitting, setIsSubmitting] = React.useState(false)
  let [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  let currentUser = connectUsers?.find((user) => user.id === parseInt(connectUserId))

  return (
    <>
      <Formik
        initialValues={{
          id: parseInt(connectUserId),
          connect_customer_id: parseInt(connectCustomerId),
          connect_channel_id: parseInt(connectChannelId),
          name: currentUser?.name,
          email: currentUser?.email,
          bushel_id: currentUser?.bushel_id,
          _revision: currentUser?._revision,
        }}
        validationSchema={userFormSchema}
        onSubmit={async (
          { id, connect_customer_id, connect_channel_id, name, email, bushel_id, _revision },
          _formikActions
        ) => {
          setIsSubmitting(true)
          await saveConnectUser(
            {
              id,
              connect_customer_id,
              connect_channel_id,
              name,
              email,
              bushel_id,
              _revision,
            },
            {
              onError: (error) => {
                // @ts-ignore
                _formikActions.setErrors(error?.response?.data?.validation_messages)
                toast({
                  status: 'error',
                  description: `Failed to ${id ? 'update' : 'create'} user`,
                })
              },
              onSuccess: () => {
                toast({
                  description: `Successfully ${id ? 'updated' : 'created'} user `,
                })
              },
              onSettled: async (res) => {
                await queryClient.invalidateQueries({ queryKey: ['connect-users'] })
                setIsSubmitting(false)
                // res is undefined onError, redirect when defined
                if (res) {
                  navigate(
                    `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${connectChannelId}/users`
                  )
                }
              },
            }
          )
        }}
      >
        {(formikProps: FormikProps<UserFormProps>) => (
          <>
            {header}
            <ConfigCard
              header={
                <Box mb={6}>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={
                      !hasPermission('connect_users_edit') ||
                      isDeleting ||
                      !currentUser ||
                      isSubmitting
                    }
                    onClick={() => {
                      setShowDeleteDialog(true)
                    }}
                  >
                    DELETE
                  </Button>
                  <Button
                    as={Link}
                    to={`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${connectChannelId}/users`}
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={isSubmitting}
                    isLoading={false}
                  >
                    CANCEL
                  </Button>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="primary"
                    isDisabled={!hasPermission('connect_users_edit') || isSubmitting}
                    isLoading={isSubmitting}
                    onClick={() => {
                      formikProps.submitForm()
                    }}
                  >
                    SAVE
                  </Button>
                </Box>
              }
            >
              <Form>
                <Stack spacing={2}>
                  <FormRow rowProps={{ mb: 5 }}>
                    <Field
                      name="name"
                      label="Name"
                      component={FormikTextField}
                      formControlProps={{ ...textProps }}
                    />
                    <Field
                      name="email"
                      label="Email"
                      component={FormikTextField}
                      formControlProps={{ ...textProps }}
                    />
                  </FormRow>
                  <FormRow rowProps={{ mb: 5 }}>
                    <Field
                      name="bushel_id"
                      label="Bushel ID"
                      component={FormikTextField}
                      formControlProps={{ ...textProps }}
                    />
                  </FormRow>
                </Stack>
              </Form>
            </ConfigCard>
          </>
        )}
      </Formik>
      <Dialog
        title="Delete Connect User"
        isOpen={showDeleteDialog}
        onClose={() => setIsDeleting(false)}
        actions={
          <>
            <Button size="sm" variant="ghost" onClick={() => setShowDeleteDialog(false)} mr={2}>
              Cancel
            </Button>

            <Button
              size="sm"
              colorScheme="primary"
              isLoading={isDeleting}
              onClick={async () => {
                setIsDeleting(true)
                await deleteConnectUser(currentUser, {
                  onSuccess: async () => {
                    await queryClient.invalidateQueries({ queryKey: ['connect-channels'] })
                    toast({ description: 'Successfully deleted Connect Channel' })
                    navigate(`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels`)
                  },
                  onError: () => {
                    toast({ status: 'error', description: 'Failed to delete Connect Channel' })
                  },
                  onSettled: () => {
                    setShowDeleteDialog(false)
                  },
                })
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Column>
          <Box>
            <Box>Are you sure you want to delete this Connect User</Box>

            {currentUser && (
              <ul>
                <li>{currentUser.name}</li>
              </ul>
            )}
          </Box>
        </Column>
      </Dialog>
    </>
  )
}
