import React, { PropsWithChildren } from 'react'
import { Redirect } from '@reach/router'
import CompanyRoutes from 'src/companies/routes'
import { Placeholders } from 'src/ui'
import BuildManagerPage from 'src/companies/build-manager'
import CompanyBranding from 'src/companies/branding/index'
import { CentreEnvironment } from 'src/api/centre'
import type { CompaniesProps } from 'src/companies'
import {
  DEFAULT_ENVIRONMENT,
  findCompany,
  useEnvSync,
  useSlugSync,
  validEnvironment,
} from 'src/companies/routes/helpers'

interface ValidatorProps extends CompaniesProps {
  env: CentreEnvironment
  slug: string
}

export function ValidatedCompanyRoutes(props: ValidatorProps) {
  const company = findCompany(props.slug, props.companies.data)

  return (
    <ValidateEnvironmentAndSlug key={`${props.env}-${props.slug}`} {...props}>
      <CompanyRoutes {...props} company={company} />
    </ValidateEnvironmentAndSlug>
  )
}

export function ValidatedBuildManagerRoutes(props: ValidatorProps) {
  const company = findCompany(props.slug, props.companies.data)

  return (
    <ValidateSlug key={`build-manager-${props.slug}`} {...props}>
      <BuildManagerPage {...props} company={company} companies={props.companies.data} />
    </ValidateSlug>
  )
}

export function ValidatedCompanyBranding(props: ValidatorProps) {
  return (
    <ValidateSlug key={`branding-${props.slug}`} {...props}>
      <CompanyBranding {...props} companies={props.companies.data} />
    </ValidateSlug>
  )
}

///////////////////////////////////////////////////////////////////////////////

type ValidateEnvProps = PropsWithChildren<
  Pick<ValidatorProps, 'env' | 'resetCompanies' | 'settings' | 'setSettingsState'>
>

function ValidateEnv({
  children,
  env,
  resetCompanies,
  settings,
  setSettingsState,
}: ValidateEnvProps) {
  useEnvSync({ env, settings, resetCompanies, setSettingsState })

  if (!validEnvironment(env)) {
    // invalid environment from url, redirect to default
    return (
      <Redirect noThrow to={`/companies/${DEFAULT_ENVIRONMENT}/${settings.companySlug || ''}`} />
    )
  }

  return <>{children}</>
}

function ValidateSlug({
  children,
  slug,
  companies,
  settings,
  setSettingsState,
}: PropsWithChildren<ValidatorProps>) {
  const company = findCompany(slug, companies.data)

  useSlugSync({ slug, company, settings, setSettingsState })

  if (!company) {
    // company slug not found
    return <Placeholders.FailedState message={`No company found for slug "${slug}".`} />
  }

  return <>{children}</>
}

///////////////////////////////////////////////////////////////////////////////

function ValidateEnvironmentAndSlug({
  children,
  env,
  slug,
  settings,
  companies,
  resetCompanies,
  loadCompanies,
  setSettingsState,
}: PropsWithChildren<ValidatorProps>) {
  // These components are responsible for validating and syncing url parameters
  // New, valid, information from the url updates the settings.
  // Settings are used for fallback values when url-params are missing.
  return (
    <ValidateEnv {...{ env, resetCompanies, settings, setSettingsState }}>
      <ValidateSlug
        {...{
          env,
          slug,
          settings,
          companies,
          resetCompanies,
          loadCompanies,
          setSettingsState,
        }}
      >
        {children}
      </ValidateSlug>
    </ValidateEnv>
  )
}
