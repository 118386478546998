import React from 'react'
import { css } from '@emotion/react'
import { useLocation, navigateAfterCompanyOrEnvChange } from 'src/routes'
import { Box, Tooltip } from 'src/ui'

const CompanyList = ({
  inputValue,
  companyList,
  settings: _ignore,
  setSettingsState,
  setIsSearching,
}) => {
  let { location } = useLocation()

  return (
    <Box data-cy="company-list" width={235} flex={1} overflowX="hidden" overflowY="scroll">
      {inputValue && companyList.length === 0 && (
        <Box pt={3} px={6} color="#aaa" fontSize={16}>
          No matches
        </Box>
      )}

      {companyList.length > 0 &&
        companyList
          .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
          .map((company) => {
            let btn = (
              <button
                key={company.id}
                onClick={() => {
                  setIsSearching(false)
                  navigateAfterCompanyOrEnvChange({
                    location,
                    slug: company.slug,
                    setSettingsState,
                  })
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 27) {
                    // pressed escape
                    setIsSearching(false)
                  }
                }}
                css={css`
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  margin-bottom: 4px;
                  color: inherit;
                  font-size: 14px;
                  text-decoration: none;
                  text-transform: none;
                  white-space: nowrap;
                  text-align: left;

                  &:hover {
                    background-color: #f0f3f6;
                  }

                  &:focus {
                    background-color: #e9f2fc;
                    outline: none;
                  }
                `}
              >
                <Box
                  width="100%"
                  mx={10}
                  css={css`
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                >
                  {company.name}
                </Box>
              </button>
            )

            // only show tooltips on overflow'd names
            if (company.name.length < 23) return btn

            return (
              <Tooltip
                key={company.id}
                label={company.name}
                aria-label={company.name}
                placement="right"
              >
                {btn}
              </Tooltip>
            )
          })}
    </Box>
  )
}

export default CompanyList
