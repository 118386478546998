import React from 'react'
import ReactDOM from 'react-dom'
import App from 'src/app'
import { setJwtAuthHeaders } from './utils/api'
import { KEYCLOAK } from './utils/auth'

if (!!process.env.REACT_APP_MOCK_MODE) {
  console.log('entering mock mode')
  require('src/mocks/browser-server')
  ReactDOM.render(<App />, document.getElementById('root'))
} else {
  KEYCLOAK.init({ onLoad: 'login-required' })
    .then((auth) => {
      if (!auth) {
        window.location.reload()
      }

      sessionStorage.setItem('kc-access-token', KEYCLOAK.token)

      ReactDOM.render(<App />, document.getElementById('root'))

      let firstCheck = Math.min(
        60,
        Math.round(KEYCLOAK.tokenParsed.exp + KEYCLOAK.timeSkew - new Date().getTime() / 1000)
      )

      setJwtAuthHeaders(KEYCLOAK.token)

      function refreshToken() {
        KEYCLOAK.updateToken(60)
          .then((refreshed) => {
            if (refreshed) {
              sessionStorage.setItem('kc-access-token', KEYCLOAK.token)
              setJwtAuthHeaders(KEYCLOAK.token)
              console.debug('Token refreshed')
            } else {
              console.warn(
                'Token not refreshed, valid for ' +
                  Math.round(
                    KEYCLOAK.tokenParsed.exp + KEYCLOAK.timeSkew - new Date().getTime() / 1000
                  ) +
                  ' seconds'
              )
            }

            let nextCheck = Math.min(
              60,
              Math.round(KEYCLOAK.tokenParsed.exp + KEYCLOAK.timeSkew - new Date().getTime() / 1000)
            )

            setTimeout(refreshToken, nextCheck * 1000)
          })
          .catch((err) => {
            console.error('Failed to refresh token', err)
          })
      }

      setTimeout(refreshToken, firstCheck * 1000)
    })
    .catch(() => {
      console.error('Authenticated Failed')
    })
}
