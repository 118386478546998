import React from 'react'
import { Box, Row, Button } from 'src/ui'
import { Formik, Form, Field } from 'formik'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import * as api from 'src/utils/api'
import { TextField, Select, Switch } from 'src/ui/formik'
import { hasPermission } from 'src/utils/permissions'
import * as Yup from 'yup'
import { Table2 as Table } from 'src/ui'

interface NewFuturesFormProps {
  company: Company
  availableCommodities: Array<string>
  saveFuturesConfig: UseMutateAsyncFunction<
    api.ApiResponse<OfferManagementMapping>,
    unknown,
    {
      futuresConfig: OfferManagementMapping
      onSuccess?: () => void
    },
    unknown
  >
}
const FormSchema = Yup.object().shape({
  futures_product_map_json: Yup.array()
    .of(
      Yup.object().shape({
        display_name: Yup.string(),
        display: Yup.string(),
        enabled: Yup.boolean(),
      })
    )
    .test('hasAMapping', 'You must enable a future to save', (value) =>
      value.some((mapping) => mapping.enabled)
    ),
})

const futuresProductMapJSON = (array) => {
  return array.map((commodity) => {
    return { name: commodity, display_name: '', display: '', enabled: false }
  })
}

const formatProductMap = (products) => {
  return products
    .filter((product) => product.enabled)
    .map((product) => ({
      name: product.name,
      display_name: product.display_name || product.name,
      display: product.display || 'Trade',
    }))
}

const NewFuturesForm = ({
  company,
  saveFuturesConfig,
  availableCommodities,
}: NewFuturesFormProps) => {
  const display = futuresProductMapJSON(availableCommodities)

  return (
    <Formik
      initialValues={{
        company_id: company.id,
        futures_product_map_json: futuresProductMapJSON(availableCommodities),
      }}
      validateOnChange={true}
      validationSchema={FormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        values.futures_product_map_json = formatProductMap(values.futures_product_map_json)
        await saveFuturesConfig({
          futuresConfig: {
            cash_bid_product_map_json: null,
            created_at: null,
            updated_at: null,
            id: null,
            elevator_id: null,
            cqg_location_name: null,
            ...values,
          },
          onSuccess: () => {
            setSubmitting(false)
          },
        })
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Box py={2} mb={4} borderRadius={3} bg="white" boxShadow="md">
            <Row justifyContent="space-between" alignItems="center" px={6} cursor="pointer">
              <Box flex={1}>
                <Row width="100%" height="62px" justifyContent="space-between" alignItems="center">
                  <>
                    <Row alignItems="center" mb={5} mt={4}></Row>
                    <p css={{ color: 'red' }}>{errors.futures_product_map_json}</p>
                    <Box pr={2}>
                      <Button
                        key="save"
                        type="submit"
                        width="76px"
                        size="sm"
                        colorScheme="primary"
                        isDisabled={!hasPermission('offer_management_edit') && isSubmitting}
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </>
                </Row>
              </Box>
            </Row>

            <Table striped>
              <thead className="thead">
                <tr>
                  <th>CQG Symbol</th>
                  <th>Display Name</th>
                  <th>Bid Type</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                {display.map((product, index) => (
                  <tr key={product.name}>
                    <td css={{ width: '200px' }}>
                      <p>{product.name}</p>
                    </td>
                    <td css={{ width: '250px' }}>
                      <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                        <Field
                          name={`futures_product_map_json.${index}.display_name`}
                          component={TextField}
                          size="sm"
                          placeholder={product.display_name}
                        />
                      </Box>
                    </td>
                    <td css={{ width: '200px' }}>
                      <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                        <Field
                          name={`futures_product_map_json.${index}.display`}
                          component={Select}
                          size="sm"
                          placeholder="Select Option"
                          options={[
                            { value: 'Trade', label: 'Trade' },
                            { value: 'Last Bid', label: 'Last Bid' },
                          ]}
                        />
                      </Box>
                    </td>
                    <td css={{ width: '200px' }}>
                      <Row justifyContent="space-between" alignItems="center">
                        <Row>
                          <Box flex={1} minWidth={0} mt={2} height={'40px'}>
                            <Field
                              name={`futures_product_map_json.${index}.enabled`}
                              component={Switch}
                              isChecked={false}
                            ></Field>
                          </Box>
                        </Row>
                        <Box></Box>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default NewFuturesForm
