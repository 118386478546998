import { QueryObserverResult, useMutation, useQuery } from '@tanstack/react-query'
import {
  FindOrCreateTenantBySlug,
  GetConfigurables,
  UpdateConfigurables,
} from 'src/api/microservices/aggregator'

interface TenantConfigResponse {
  key: string
  label: string
  description: string
  isFeature: boolean
  group: string
  dataType: string
  options: string[]
}
interface TenantUpdateResponse {
  data: [
    {
      value: string
      key: string
    }
  ]
}

export const useFindTenantBySlug = (slug: string): QueryObserverResult<Tenant[], unknown> =>
  useQuery({
    queryKey: ['aggregator-config-slug', slug],
    queryFn: async () => {
      let [err, data] = await FindOrCreateTenantBySlug(slug)
      if (err) throw err
      return data.data
    },
  })

export const useGetConfigurables = (
  slug: string
): QueryObserverResult<TenantConfigResponse[], unknown> =>
  useQuery({
    queryKey: ['aggregator-config', slug],
    queryFn: async () => {
      let [err, response] = await GetConfigurables(slug)
      if (err) throw err
      return response.data
    },
  })

export const useUpdateConfigurables = (slug: string) => {
  return useMutation({
    mutationFn: async (payload: { data: TenantUpdateResponse[] }) => {
      const [err, response] = await UpdateConfigurables(payload, slug)
      if (err) throw err
      return response
    },
  })
}
