import React, { useEffect } from 'react'
import { Redirect } from '@reach/router'
import { RootState, Dispatch } from 'src/store'
import { CentreEnvironment } from 'src/api/centre'
import { Placeholders } from 'src/ui'
import { findCompany, validEnvironment } from 'src/companies/routes/helpers'

interface CompaniesDefaultProps {
  env?: CentreEnvironment // route param
  companies: RootState['companies']
  settings: RootState['settings']
  loadCompanies: Dispatch['companies']['loadAll']
  setSettingsState: Dispatch['settings']['setState']
}

export function CompaniesDefault({
  env,
  settings,
  companies,
  loadCompanies,
  setSettingsState,
}: CompaniesDefaultProps) {
  const { companySlug, centreAPIEndpoint } = settings

  useEffect(() => {
    if (env && env !== centreAPIEndpoint) {
      setSettingsState({ centreAPIEndpoint: env })
      loadCompanies()
    }
  }, [centreAPIEndpoint, env, loadCompanies, setSettingsState])

  if (!env || !validEnvironment(env)) {
    return <Redirect noThrow to={`/companies/${centreAPIEndpoint}`} />
  }

  if (companySlug && !!findCompany(companySlug, companies.data)) {
    return <Redirect noThrow to={`/companies/${centreAPIEndpoint}/${companySlug}`} />
  }

  return <Placeholders.EmptyState message="Select a company" />
}
