import React from 'react'
import { Redirect } from '@reach/router'
import { FlexRouter, AuthRoute } from 'src/routes'
import ConnectCustomers from 'src/connect/customers'
import ConnectChannels from 'src/connect/channels'
import ConnectSubscriptions from 'src/connect/subscriptions'
import ConnectUsers from 'src/connect/users'

interface ConnectRoutesProps {
  env: string
}

function ConnectRoutes({ env }: ConnectRoutesProps) {
  return (
    // relative to /connect/:env/*
    <>
      <FlexRouter>
        <AuthRoute
          path="/customers/:connectCustomerId/channels/:connectChannelId/users/*"
          as={ConnectUsers}
        />
        <AuthRoute
          path="/customers/:connectCustomerId/channels/:connectChannelId/users"
          as={ConnectUsers}
        />
        <AuthRoute
          path="/customers/:connectCustomerId/channels/:connectChannelId/subscriptions/*"
          as={ConnectSubscriptions}
        />
        <AuthRoute
          path="/customers/:connectCustomerId/channels/:connectChannelId/subscriptions"
          as={ConnectSubscriptions}
        />
        <AuthRoute path="/customers/:connectCustomerId/channels" as={ConnectChannels} />
        <AuthRoute path="/customers/*" as={ConnectCustomers} />

        <AuthRoute
          default
          as={({ env }) => <Redirect noThrow to={`/connect/${env}/customers`} />}
          env={env}
        />
      </FlexRouter>
    </>
  )
}

export default ConnectRoutes
