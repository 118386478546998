import React from 'react'
import { connect } from 'react-redux'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import * as Yup from 'yup'
import { Formik, Form, Field, FormikProps } from 'formik'
import { TextField as FormikTextField } from 'src/ui/formik'
import {
  Box,
  Stack,
  Table2,
  useTable,
  Button,
  Placeholders,
  InputRightElement,
  ViewIcon,
  ViewOffIcon,
} from 'src/ui'
import ConfigPanel, { PanelState } from 'src/companies/configurations/config-panel'
import { useErrorToast, useToast } from 'src/utils/toast'
import { centreAPIs } from 'src/utils/api'
import * as queries from 'src/utils/queries'
import { Dispatch } from 'src/store'
import { hasPermission } from 'src/utils/permissions'

// FORM
///////////////////////////////////////////////////////////////////////////////

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid Email').required('Required'),
  password: Yup.string().required('Required'),
})

interface AdminUsersPanelFormProps {
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
}

const AdminUsersPanelForm: React.FC<AdminUsersPanelFormProps> = ({ formikProps, formData }) => {
  let { submittedAt } = formData
  let { submitForm } = formikProps

  const [show, setShow] = React.useState(false)
  const revealPassword = () => setShow(!show)

  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])
  return (
    <Form>
      <Stack
        isInline
        spacing={3}
        align="center"
        height="100px"
        display="flex"
        px={6}
        shouldWrapChildren
      >
        <Field
          name="firstName"
          label="First Name"
          component={FormikTextField}
          formControlProps={{ height: '62px', mb: null, width: '100%' }}
        />

        <Field
          name="lastName"
          label="Last Name"
          component={FormikTextField}
          formControlProps={{ height: '62px', mb: null, width: '100%' }}
        />

        <Field
          name="email"
          label="Email"
          component={FormikTextField}
          formControlProps={{ height: '62px', mb: null, width: '100%' }}
        />

        <Field
          name="password"
          label="Password"
          component={FormikTextField}
          type={show ? 'text' : 'password'}
          formControlProps={{ height: '62px', mb: null, width: '100%' }}
          rightElement={
            <InputRightElement>
              <Button
                size="xs"
                onClick={revealPassword}
                variant="unstyled"
                textAlign="center"
                mr={6}
                tabIndex={-1}
              >
                {show ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          }
        />
      </Stack>
    </Form>
  )
}

// DETAIL
///////////////////////////////////////////////////////////////////////////////

interface AdminUsersProps {
  status: string
  adminUsers: any[]
}

const AdminUsers = ({ adminUsers, status }: AdminUsersProps) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        accessor: 'id',
        Header: 'ID',
      },
      {
        id: 'firstName',
        accessor: 'first_name',
        Header: 'First Name',
      },
      {
        id: 'lastName',
        accessor: 'last_name',
        Header: 'Last Name',
      },
      {
        id: 'email',
        accessor: (row) => {
          if (row.user_emails.length === 0) return null
          return row.user_emails[0].email
        },
        Header: 'Email',
      },
    ],
    []
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: adminUsers,
    tableOptions: {
      initialState: {
        sortBy: [{ id: 'id' }],
      },
    },
    columns,
  })

  if (adminUsers.length === 0 && status === 'success') {
    return <Placeholders.EmptyState mt="0" />
  }

  return (
    <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
      <Table2.Header headerGroups={headerGroups} />
      <Table2.Body rows={rows} getTableBodyProps={getTableBodyProps} prepareRow={prepareRow} />
    </Table2>
  )
}

// PANEL
/////////////////////////////////////////////////////////////////////
type AdminUsersPanelProps = {
  company: Company
  formikProps: FormikProps<any>
}

interface AdminUsersPanelFormProps {
  formikProps: FormikProps<any>
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
}

type useAdminUsersArgs = { companySlug: string }

const useAdminUsers = ({ companySlug }: useAdminUsersArgs) => {
  return useQuery({
    queryKey: ['cms-users', { companySlug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getAdminUsers({ companySlug })
      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })
}

const AdminUsersPanel: React.FC<AdminUsersPanelProps> = ({ company }) => {
  const toast = useToast()
  const errorToast = useErrorToast()
  const queryClient = useQueryClient()
  let [propertiesState, setPropertiesState] = React.useState<PanelState>('collapsed')
  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })

  let adminUsersQuery = useAdminUsers({
    companySlug: company.slug,
  })

  React.useEffect(() => {
    if (adminUsersQuery.error && !(adminUsersQuery.status === 'loading')) {
      setPropertiesState('expanded')
    }
  }, [adminUsersQuery.error, adminUsersQuery.status])

  const stopEditing = () => {
    setPropertiesState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  const saveAdminUser = async (adminUser) => {
    let [err, response] = await centreAPIs.createAdminUser({
      adminUser,
    })
    if (err) throw err
    return [err, response]
  }

  let { mutateAsync: saveAdminUserMutation } = useMutation({
    mutationFn: saveAdminUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['cms-users'] })
      toast({ description: 'Sucessly saved admin user' })
      stopEditing()
    },
    onError: (err) => {
      errorToast(err, 'save', 'admin user')
      setFormData({ submittedAt: null, isSubmitting: false })
    },
  })

  let adminUsers = adminUsersQuery.data ? adminUsersQuery.data : []

  return (
    <>
      <ConfigPanel
        label="Admin Users"
        expanded={['expanded', 'editing'].includes(propertiesState)}
        copyPermission="company_config_copy"
        editPermission="company_config_edit"
        formData={formData}
        panelState={propertiesState}
        onSave={() => setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))}
        onCancelEdit={stopEditing}
        onChange={(e, expanded) => {
          if (propertiesState === 'editing') return // ignore changes while editing
          setPropertiesState(expanded ? 'expanded' : 'collapsed')
        }}
        controls={
          propertiesState !== 'editing' && (
            <Box pr={2}>
              {hasPermission('company_admin_create') && (
                <Button
                  width="76px"
                  size="sm"
                  colorScheme="primary"
                  isDisabled={!(adminUsersQuery.data && !adminUsersQuery.isLoading)}
                  onClick={(e) => {
                    e.stopPropagation()
                    setPropertiesState('editing')
                  }}
                >
                  Add
                </Button>
              )}
            </Box>
          )
        }
      >
        {queries.areAnyLoading(adminUsersQuery) && <Placeholders.LoadingState mt="0" />}
        {queries.areAnyFailed(adminUsersQuery) && <Placeholders.FailedState mt="0" />}
        {queries.areAllLoaded(adminUsersQuery) &&
          propertiesState !== 'editing' &&
          adminUsers.length === 0 && <Placeholders.EmptyState mt="0" message="No users found" />}
        {queries.areAllLoaded(adminUsersQuery) &&
          propertiesState !== 'editing' &&
          adminUsers.length !== 0 && (
            <AdminUsers status={adminUsersQuery.status} adminUsers={adminUsers} />
          )}
        {propertiesState === 'editing' && (
          <>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
              }}
              validateOnChange={false}
              validationSchema={FormSchema}
              onSubmit={async (values) => {
                setFormData((state) => ({ ...state, isSubmitting: true }))

                await saveAdminUserMutation({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  password: values.password,
                  companySlug: company.slug,
                  isAdmin: true,
                  hasGlobalSend: true,
                })

                setFormData({ submittedAt: null, isSubmitting: false })
              }}
            >
              {(formikProps: FormikProps<any>) => (
                <AdminUsersPanelForm formikProps={formikProps} formData={formData} />
              )}
            </Formik>
          </>
        )}
      </ConfigPanel>
    </>
  )
}

const mapDispatch = ({ notifications }: Dispatch) => ({
  errorNotification: notifications.createError,
  successNotification: notifications.createSuccess,
})

export default connect(
  null, // mapState
  mapDispatch
)(AdminUsersPanel)
