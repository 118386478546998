import React, { SetStateAction, useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { AuthRoute, FlexRouter } from 'src/routes'
import { Box, Button, Placeholders, Tab, TabList, Tabs } from 'src/ui'
import { FlavorDialog, FlavorWrapper } from 'src/companies/branding/flavor-create-helper'
import { CreateBuildForm } from 'src/companies/build-manager/create-build'
import { BuildConfigPage } from 'src/companies/build-manager/build-config'
import { Dispatch } from 'src/store'
import { CreateAcadiaBuildForm } from './create-acadia-build'
import { useFlagr } from '../../utils/flagr'

interface BuildManagerPageProps {
  setSettingsState: Dispatch['settings']['setState']
  slug: string
  company: Company
  companies: Company[]
}

export default function BuildManagerPage({
  slug,
  company,
  companies,
  setSettingsState,
  ...rest
}: BuildManagerPageProps) {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [flagrQuery, enabled] = useFlagr('bap_enable_acadia_builds')

  useEffect(() => {
    setIsLoading(flagrQuery.isLoading)
    setIsError(flagrQuery.isError)
  }, [flagrQuery])

  useEffect(() => {
    setSettingsState({ showCompanySelectorDialog: true, showCentreEnvironment: false })
  }, [setSettingsState])

  if (isLoading) return <Placeholders.LoadingState />
  if (isError) return <Placeholders.FailedState />

  return (
    <FlavorWrapper
      companies={companies}
      slug={slug}
      component={BuildManagerRoutes}
      componentProps={{ company, acadiaBuildsEnabled: enabled, ...rest }}
    />
  )
}
interface BuildManagerRoutesProps {
  company: Company
  acadiaBuildsEnabled: boolean
  flavor: Flavor
}

function BuildManagerRoutes({
  company,
  acadiaBuildsEnabled,
  flavor,
  ...rest
}: BuildManagerRoutesProps) {
  const [isEditingFlavor, setIsEditingFlavor] = useState(false)
  return (
    <>
      <FlexRouter>
        <AuthRoute
          path="/configuration"
          header={
            <BuildManagerHeader
              type="build-config"
              company={company}
              setIsEditingFlavor={setIsEditingFlavor}
              acadiaBuildsEnabled={acadiaBuildsEnabled}
              {...rest}
            />
          }
          company={company}
          flavor={flavor}
          as={BuildConfigPage}
        />
        <AuthRoute
          default
          header={
            <BuildManagerHeader
              type="build-create"
              company={company}
              setIsEditingFlavor={setIsEditingFlavor}
              acadiaBuildsEnabled={acadiaBuildsEnabled}
              {...rest}
            />
          }
          company={company}
          flavor={flavor}
          as={CreateBuildForm}
        />
        {acadiaBuildsEnabled && (
          <AuthRoute
            path="/acadia"
            header={
              <BuildManagerHeader
                type="acadia-build-create"
                company={company}
                setIsEditingFlavor={setIsEditingFlavor}
                acadiaBuildsEnabled={acadiaBuildsEnabled}
                {...rest}
              />
            }
            company={company}
            flavor={flavor}
            as={CreateAcadiaBuildForm}
          />
        )}
      </FlexRouter>
      <FlavorDialog
        isOpen={isEditingFlavor}
        onClose={() => setIsEditingFlavor(false)}
        onConfirm={() => setIsEditingFlavor(false)}
        flavor={flavor}
      />
    </>
  )
}
interface BuildManagerHeaderProps {
  type: 'build-config' | 'build-create' | 'acadia-build-create'
  company: Company
  setIsEditingFlavor: React.Dispatch<SetStateAction<boolean>>
  acadiaBuildsEnabled: boolean
}

function BuildManagerHeader({
  type,
  company,
  setIsEditingFlavor,
  acadiaBuildsEnabled,
}: BuildManagerHeaderProps) {
  return (
    <Box display="inline-flex" alignItems="center" justifyContent="space-between">
      <Tabs variant="unstyled">
        <TabList>
          <Tab
            as={Link}
            to={`/companies/${company.slug}/build-manager`}
            fontWeight={700}
            color={type === 'build-create' ? 'blue.500' : 'gray.500'}
            borderBottom="2px"
            borderBottomColor={type === 'build-create' ? 'blue.500' : 'transparent'}
          >
            Create a Build
          </Tab>
          {acadiaBuildsEnabled && (
            <Tab
              as={Link}
              to={`/companies/${company.slug}/build-manager/acadia`}
              fontWeight={700}
              color={type === 'acadia-build-create' ? 'blue.500' : 'gray.500'}
              borderBottom="2px"
              borderBottomColor={type === 'acadia-build-create' ? 'blue.500' : 'transparent'}
            >
              Create an Acadia Build
            </Tab>
          )}
          <Tab
            as={Link}
            to={`/companies/${company.slug}/build-manager/configuration`}
            fontWeight={700}
            color={type === 'build-config' ? 'blue.500' : 'gray.500'}
            borderBottom="2px"
            borderBottomColor={type === 'build-config' ? 'blue.500' : 'transparent'}
          >
            Build Config
          </Tab>
        </TabList>
      </Tabs>
      <Button variant="ghost" size="sm" onClick={() => setIsEditingFlavor(true)}>
        Edit Flavor
      </Button>
    </Box>
  )
}
