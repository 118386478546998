import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, FormikProps } from 'formik'
import { uniq } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import { DeleteDialog } from 'src/endpoints/dialogs'
import { Select as FormikSelect } from 'src/ui/formik'
import {
  Box,
  CloseIcon,
  Column,
  DeleteIcon,
  Row,
  Button,
  Chip,
  Checkbox,
  FormRow,
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
  Input,
  Panel,
  Stack,
  Tooltip,
} from 'src/ui'
import { useToast } from 'src/utils/toast'

const sortFeatures = (features) => [...features].sort((fa, fb) => (fa < fb ? -1 : fb < fa ? 1 : 0))
const sortCompanies = (companies) =>
  [...companies].sort(({ name: na }, { name: nb }) =>
    na.toLowerCase() < nb.toLowerCase() ? -1 : 1
  )

const SubscriptionSchema = Yup.object().shape({
  company_slug: Yup.string().required('Required'),
  environment: Yup.string().required('Required'),
  features: Yup.array().ensure().min(1, 'Select at least one feature'),
})

interface SubscriptionProps {
  endpoint: Endpoint
  isNew: boolean
  subscription: EndpointSubscription
  companies: ConfiguratorCompany[]
  handleRemove: any
  environments: Environment[]
  features: string[]
  handleSave: (
    args: {
      endpoint: Endpoint
      subscription: EndpointSubscription
      isNew: boolean
    },
    options: {
      onSuccess: () => void
      onError: () => void
    }
  ) => void
}

const Subscription: React.FC<SubscriptionProps> = ({
  endpoint,
  isNew,
  subscription,
  environments,
  companies,
  features,
  handleRemove,
  handleSave,
}) => {
  let [expanded, setExpanded] = React.useState(isNew ? true : false)
  let [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  let [newCustomEntityKey, setNewCustomEntityKey] = React.useState('')
  let [isSaving, setIsSaving] = React.useState(false)
  let toast = useToast()
  const queryClient = useQueryClient()

  const handleStartDelete = (e) => {
    e.stopPropagation()
    setShowDeleteDialog(true)
  }

  const handleCancelDelete = () => {
    setShowDeleteDialog(false)
  }

  return (
    <>
      <DeleteDialog
        isOpen={showDeleteDialog}
        onClose={handleCancelDelete}
        componentTitle="Subscription"
        onConfirm={async () => {
          await handleRemove(
            { endpoint, subscription },
            {
              onSuccess: async () => {
                setShowDeleteDialog(false)
                await queryClient.invalidateQueries({ queryKey: ['endpoints'] })
                toast({
                  description: 'Successfully removed subscription',
                })
              },
              onError: () => {
                toast({
                  status: 'error',
                  description: 'Failed to remove subscription',
                })
                setShowDeleteDialog(false)
              },
            }
          )
        }}
      />

      <Panel
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        hideToggleIcon={isNew} // remove icon on new schedule (`undefined` lets it use the default)
        header={
          <Row width="100%" justifyContent="space-between" alignItems="center">
            <Row alignItems="center">
              {subscription.environment && (
                <Chip
                  label={subscription.environment.toUpperCase()}
                  color={subscription.environment === 'prod' ? 'blue' : 'yellow'}
                />
              )}

              {subscription.company_slug && <Box px={3}>{subscription.company_slug}</Box>}

              {isNew && <Box>New Subscription</Box>}
            </Row>

            {!isNew && (
              <Box>
                <Tooltip
                  label="Remove Subscription"
                  aria-label="Remove Subscription"
                  placement="left"
                >
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Remove Subscription"
                    size="lg"
                    variant="ghost"
                    isRound
                    color="gray.600"
                    onClick={handleStartDelete}
                  />
                </Tooltip>
              </Box>
            )}
          </Row>
        }
      >
        {expanded && (
          <Column width="100%">
            <Formik
              initialValues={subscription}
              validationSchema={SubscriptionSchema}
              onSubmit={async (values) => {
                let submissionValues = { ...values, features: uniq(values.features) }
                setIsSaving(true)
                await handleSave(
                  {
                    endpoint,
                    subscription: submissionValues,
                    isNew,
                  },
                  {
                    onSuccess: async () => {
                      await queryClient.invalidateQueries({ queryKey: ['endpoints'] })
                      toast({
                        description: `Successfully ${isNew ? 'created' : 'updated'} subscription`,
                      })
                      if (isNew) {
                        handleRemove()
                      } else {
                        setIsSaving(false)
                        setExpanded(false)
                      }
                    },
                    onError: () => {
                      toast({ status: 'error', description: 'Failed to save subscription' })
                      setIsSaving(false)
                    },
                  }
                )
              }}
            >
              {(formikProps: FormikProps<any>) => {
                let standardFeatures = features
                let customFeatures = formikProps.values.features.filter(
                  (value) => !features.includes(value)
                )

                const createCustomEntity = (entity) => {
                  let nextValue = formikProps.values.features.concat(
                    `${subscription.company_slug}~${entity}`
                  )
                  formikProps.setFieldValue('features', uniq(nextValue))
                  setNewCustomEntityKey('')
                }

                return (
                  <Form>
                    <FormRow>
                      <Field
                        id="environment"
                        name="environment"
                        label="Environment"
                        component={FormikSelect}
                        selectProps={{ isClearable: false }}
                        disabled={!isNew}
                        options={environments.map(({ slug, name }) => ({
                          value: slug,
                          label: name,
                        }))}
                      />

                      <Field
                        id="company_slug"
                        name="company_slug"
                        label="Company"
                        component={FormikSelect}
                        selectProps={{ isClearable: false }}
                        disabled={!isNew}
                        options={sortCompanies(companies).map((c) => ({
                          value: c.slug,
                          label: c.name,
                        }))}
                      />
                    </FormRow>

                    <FormControl
                      isInvalid={formikProps.touched.features && !!formikProps.errors.features}
                    >
                      <FormLabel fontSize="sm" color="gray.500">
                        Features
                      </FormLabel>

                      <Stack spacing={2} shouldWrapChildren direction="column" mb="6">
                        {sortFeatures(standardFeatures).map((feature) => (
                          <Field key={feature} name="features">
                            {({ field, form }) => (
                              <Checkbox
                                key={feature}
                                isChecked={field.value.includes(feature)}
                                onChange={() => {
                                  let nextValue = field.value.includes(feature)
                                    ? field.value.filter((value) => value !== feature)
                                    : field.value.concat(feature)

                                  form.setFieldValue('features', nextValue)
                                }}
                              >
                                {feature}
                              </Checkbox>
                            )}
                          </Field>
                        ))}
                      </Stack>
                      {formikProps.touched.features && formikProps.errors.features && (
                        <FormErrorMessage
                          mb={2}
                          css={{
                            svg: {
                              display: 'none',
                            },
                          }}
                        >
                          {formikProps.errors.features}
                        </FormErrorMessage>
                      )}

                      <Stack spacing={2} shouldWrapChildren direction="column">
                        <FormLabel fontSize="sm" color="gray.500">
                          Custom Entities
                        </FormLabel>
                        <FormLabel fontSize="xs" color="gray.400">
                          Company slug will be automatically prepended to all custom entity names
                        </FormLabel>
                        {sortFeatures(uniq(customFeatures)).map((feature) => (
                          <Row key={feature}>
                            <Box mr={2}>
                              <IconButton
                                icon={<CloseIcon />}
                                aria-label="Remove Custom Entity"
                                size="xs"
                                variant="ghost"
                                color="gray.600"
                                onClick={() => {
                                  let nextValue = formikProps.values.features.filter(
                                    (value) => value !== feature
                                  )
                                  formikProps.setFieldValue('features', nextValue)
                                }}
                              />
                            </Box>
                            <Box fontSize="md">
                              {feature.replace(`${subscription.company_slug}~`, '')}
                            </Box>
                          </Row>
                        ))}
                        <FormControl>
                          <Row alignItems="center">
                            <Input
                              placeholder="Custom Entity Name"
                              value={newCustomEntityKey}
                              width="33%"
                              size="sm"
                              minWidth="200px"
                              onChange={(e) => setNewCustomEntityKey(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  createCustomEntity(newCustomEntityKey)
                                }
                              }}
                            />
                            <Button
                              type="button"
                              size="sm"
                              isDisabled={newCustomEntityKey.length === 0}
                              onClick={() => {
                                createCustomEntity(newCustomEntityKey)
                              }}
                              ml={5}
                            >
                              Add
                            </Button>
                          </Row>
                        </FormControl>
                      </Stack>
                    </FormControl>

                    <Row justifyContent="flex-end">
                      <Box mx={2}>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => {
                            formikProps.resetForm()
                            if (isNew) {
                              handleRemove()
                            }
                            setExpanded(false)
                          }}
                          isDisabled={isSaving}
                        >
                          Cancel
                        </Button>
                      </Box>

                      <Box mx={2}>
                        <Button
                          type="submit"
                          size="sm"
                          colorScheme="primary"
                          isLoading={isSaving}
                          isDisabled={!formikProps.dirty || isSaving}
                        >
                          Save
                        </Button>
                      </Box>
                    </Row>
                  </Form>
                )
              }}
            </Formik>
          </Column>
        )}
      </Panel>
    </>
  )
}

export default Subscription
