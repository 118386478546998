import { Button, Stack, Select } from '@chakra-ui/react'
import { navigate } from '@reach/router'
import moment from 'moment'
import React from 'react'
import { translatorEnvironmentUrl } from 'src/translators/utils'
import { Box, Card, ChevronLeftIcon } from 'src/ui'
import { getCompanyToken, getDetailMetrics } from 'src/utils/api'
import { DetailLabel, DetailValue } from './ui'

type CompanyDetailMetricProps = {
  companyDetrailMetric: CompanyDetailMetric
  slug: string
  scheduleName: string
  environment: string
  selectedTranslator: string
}

const MetricDetails: React.FC<CompanyDetailMetricProps> = ({
  slug,
  scheduleName,
  environment,
  selectedTranslator,
}) => {
  const [detailData, setDetailData] = React.useState<any>()
  const [recordsReturned, setRecordsReturned] = React.useState<any>()

  React.useEffect(() => {
    const getDetailedMetrics = async (job_name) => {
      if (!selectedTranslator) return
      const [, res] = await getCompanyToken({
        slug: slug,
        environment: environment,
      })
      const adapter_url = translatorEnvironmentUrl({
        translator_id: selectedTranslator,
        environment_slug: environment,
      })
      const api_token = res?.data?.data?.token
      const [, metricsRes] = await getDetailMetrics({
        adapter_url,
        api_token,
        job_name,
        records_returned: recordsReturned === undefined ? 20 : recordsReturned,
      })
      setDetailData(metricsRes?.data)
    }
    getDetailedMetrics(scheduleName)
  }, [selectedTranslator, slug, scheduleName, environment, recordsReturned])

  return (
    <Box d="flex" flexDir="column">
      <Box d="flex" mb={4} alignItems="center" justifyContent="space-between" w="100%">
        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={() => navigate(`/translators/metrics/${slug}`)}
        >
          Back to List
        </Button>
        <Box fontSize={16} color="#8c9ba5">
          {scheduleName}
        </Box>
        <Box d="flex" alignItems="center">
          <Box whiteSpace="nowrap" mr={1}>
            Page Size
          </Box>
          <RecordCountSelector
            onChange={(e) => {
              setRecordsReturned(e.currentTarget.value)
            }}
            recordsRetrieved={recordsReturned}
            maxW={100}
          />
        </Box>
      </Box>

      {!!detailData &&
        detailData.map((detail, index) => (
          <MetricDetailItem detail={detail} index={index} key={index} />
        ))}
    </Box>
  )
}
export default MetricDetails

const MetricDetailItem = ({ detail, index }) => {
  return (
    <Card key={index} mb={3}>
      <Box d="flex" alignItems="center" justifyContent="space-between" pb=".5rem">
        <Box d="flex" alignItems="center">
          <Box
            backgroundColor={detail.status === 'failure' ? 'red.500' : 'green.500'}
            w=".5rem"
            h=".5rem"
            borderRadius="100%"
            mr={2}
          ></Box>
          <Box>{moment(detail.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Box>
        </Box>
      </Box>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <Box height="62px">
            <DetailLabel label="Status" />
            <DetailValue value={detail.status} title={detail.status} />
          </Box>
        </Box>
        <Box flex={1} minWidth={0}>
          <Box height="62px">
            <DetailLabel label="Run Time" />
            <DetailValue
              value={
                !!detail.payload?.duration
                  ? Number(detail.payload?.duration / 1000).toFixed(1) + ' Seconds'
                  : undefined
              }
              title={
                !!detail.payload?.duration
                  ? Number(detail.payload?.duration / 1000).toFixed(1) + ' Seconds'
                  : undefined
              }
            />
          </Box>
        </Box>
        <Box flex={1} minWidth={0}>
          <Box height="62px">
            <DetailLabel label="Is Finished" />
            <DetailValue
              value={detail.payload?.finished}
              title={detail.payload?.finished?.toString()}
            />
          </Box>
        </Box>
        <Box flex={1} minWidth={0}>
          <Box height="62px">
            <DetailLabel label="Records Returned" />
            <DetailValue
              value={detail.payload?.recordsRetrieved}
              title={detail.payload?.recordsRetrieved}
            />
          </Box>
        </Box>
      </Stack>
    </Card>
  )
}

const RecordCountSelector = ({ onChange, recordsRetrieved, ...rest }) => {
  return (
    <Select value={recordsRetrieved} onChange={onChange} {...rest}>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option value="200">200</option>
      <option value="500">500</option>
    </Select>
  )
}
