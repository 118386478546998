import * as Yup from 'yup'
import cronstrue from 'cronstrue'

/**
 * The schedules and pools lists shouldn't use numerical index keys, and the name can be changed.
 * It's just easiest to generate a random key for them on initial load and addition.
 */
export const randKey = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

/**
 * Normalize a keyed-hash into an array of its keys.
 *
 * @param data null|Object
 */
export const keysAsArray = (data) => (data ? Object.keys(data) : [])

/**
 * Transforms portions of API-formatted data to a structure Formik can handle.
 * List-like keyed hashes turn into arrays, eg.:
 *    `{ key: { ...data }, key2: { ...data2 } }` transforms to `[ { name: key, ...data }, { name: key2, ...data2 } ]`
 */
export const normalizeToFormikState = (config: TranslatorConfig) => {
  let {
    prototype: isPrototype,
    translator_config,
    adapter_module,
    adapter_version,
    ...rest
  } = config

  return {
    prototype: isPrototype,

    global: translator_config.global,

    operations: translator_config.operations,

    adapter: {
      adapter_module: adapter_module,
      adapter_version: adapter_version,
    },

    auth: translator_config.auth,

    consul_services: keysAsArray(translator_config.consul_services).map((key) => ({
      key,
      value: translator_config.consul_services[key],
    })),

    schedules: keysAsArray(translator_config.schedules).map((key) => ({
      key: randKey(),
      name: key,
      ...translator_config.schedules[key],
    })),

    pools: keysAsArray(translator_config.pools).map((key) => ({
      key: randKey(),
      name: key,
      ...translator_config.pools[key],
    })),

    ...rest,
  }
}

/**
 * Transforms Formik data back into structure the API accepts.
 *
 * @param args Object
 */
export const normalizeToApiBody = ({
  prototype,
  adapter,
  schedules,
  pools,
  auth,
  global,
  operations,
  consul_services,
  ...rest
}) => ({
  prototype,

  adapter_module: adapter.adapter_module,

  adapter_version: adapter.adapter_version,

  translator_config: {
    created_at: new Date().toISOString(),
    auth: {
      adapter: auth.adapter,
      adapter_config: auth.adapter_config,
    },
    global,
    operations,
    consul_services: consul_services.reduce((acc, _c) => acc, {}),
    schedules: schedules.reduce((acc, { name, ...schedule }) => ({ ...acc, [name]: schedule }), {}),
    pools: pools.reduce((acc, { name, ...pool }) => ({ ...acc, [name]: pool }), {}),
  },

  ...rest,
})

/**
 * When there is no existing config for an environment, this is used for the Formik initial values.
 */
export const emptyConfig: TranslatorConfig = {
  translator_id: '',
  company_slug: '',
  environment: '',
  adapter_module: '',
  adapter_version: '',
  prototype: false,
  user: null,
  created_at: null,
  deleted_at: null,
  revision_comment: null,
  translator_config: {
    translator_name: '',
    company_slug: '',
    created_at: '',
    consul_services: {},
    auth: {},
    pools: {},
    schedules: {},
  } as TranslatorConfigConfig,
}

// @todo: some of these may benefit from having common defaults?
export const newSchedule = {
  name: '',
  adapter: '',
  type: '',
  adapter_config: null,
  priority: '',
  start_days_ago: '',
  interval_minutes: '',
  cron: '',
}

// @todo: some of these may benefit from having common defaults?
export const newPool = {
  name: '',
  max_connections: '',
  timeout: '',
  rate_limit_count: '',
  rate_limit_seconds: '',
  jdbc_info: {
    connection_url: '',
    connection_address: '',
    connection_port: '',
    user_name: '',
    password: '',
    fully_qualified_driver_name: '',
    legacy_driver: false,
  },
}

/**
 * Inputs only allow strings (not null or undef).
 * Yup.number type-checking will fail an empty string as NaN.
 * To allow an empty value, transform it to undef first.
 */
const emptyNumberTransform = (currentValue: any, origValue: any) =>
  origValue === '' ? undefined : currentValue

/**
 * Validates CRON expressions via cRonstrue lib.
 * An exception is thrown for invalid expressions.
 *
 * https://github.com/bradymholt/cRonstrue
 *
 * @param value string
 */
const cronValidator = (value) => {
  if (!value) return true

  try {
    cronstrue.toString(value)
  } catch (err) {
    return false
  }

  return true
}

/**
 * Validates that the value is empty.
 * Used when one of two properties should be filled, but not both.
 *
 * @param value any
 */
const emptyValidator = (value) => {
  return !value
}

export const cronExplained = (value) => {
  if (!value) return 'No CRON expression'

  try {
    return cronstrue.toString(value)
  } catch (err) {}

  return 'Invalid CRON expression'
}

const ScheduleSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .matches(/^[\w-]+$/, 'May only contain alphanumeric _ and - characters'),

  adapter: Yup.string().required('Required'),

  type: Yup.string().required('Required'),

  priority: Yup.number().typeError('Must be a number').required('Required'),

  start_days_ago: Yup.number().typeError('Must be a number').nullable().notRequired(),

  start_date: Yup.string().typeError('Must be a date').nullable().notRequired(),

  interval_minutes: Yup.number()
    .typeError('Must be a number')
    .nullable()
    .notRequired()
    .transform(emptyNumberTransform),

  cron: Yup.string()
    .nullable()
    .when('interval_minutes', ([val], schema) =>
      val === '' || val === null || val === undefined
        ? schema
            .required('Either interval minutes or cron is required')
            .test('valid-cron', 'Invalid CRON expression', cronValidator)
        : schema
            .nullable()
            .test('empty', 'You can not specify both interval minutes and cron.', emptyValidator)
    ),
})

const PoolSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .matches(/^[\w-]+$/, 'May only contain alphanumeric _ and - characters'),

  max_connections: Yup.number().typeError('Must be a number').required('Required'),

  timeout: Yup.number().typeError('Must be a number').required('Required'),

  rate_limit_count: Yup.number()
    .typeError('Must be a number')
    .nullable()
    .notRequired()
    .transform(emptyNumberTransform),

  rate_limit_seconds: Yup.number()
    .typeError('Must be a number')
    .nullable()
    .notRequired()
    .transform(emptyNumberTransform),

  jdbc_info: Yup.object()
    .nullable()
    .shape({
      connection_url: Yup.string().required('Required'),
      fully_qualified_driver_name: Yup.string().nullable().notRequired(),
      connection_address: Yup.string().required('Required'),
      connection_port: Yup.number().typeError('Must be a number').required('Required'),
      user_name: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
      legacy_driver: Yup.boolean().required('Required'),
    }),
})

export const TranslatorConfigSchema = Yup.object().shape({
  new_revision_comment: Yup.string().required('Required').max(1024),

  adapter: Yup.object().shape({
    adapter_module: Yup.string().required('Required'),

    adapter_version: Yup.string().required('Required'),
  }),

  auth: Yup.object().shape({
    adapter: Yup.string().required('Required'),

    adapter_config: Yup.object().nullable(),
  }),

  global: Yup.object().nullable(),

  operations: Yup.object().nullable(),

  schedules: Yup.array().of(ScheduleSchema),

  pools: Yup.array().of(PoolSchema),
})
