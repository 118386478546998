import React from 'react'
import {
  useConnectSubscriptions,
  useConnectSubscriptionMutations,
} from 'src/api/queries/connect-subscriptions'
import { useConnectChannels } from 'src/api/queries/connect-channels'
import { navigate, Link } from '@reach/router'
import { useQueryClient } from '@tanstack/react-query'
import { hasPermission } from 'src/utils/permissions'
import { Box, Button, Row, Placeholders, Table2, useTable, Column, Dialog } from 'src/ui'
import { SubscriptionAdd } from 'src/connect/subscriptions/subscription-add'
import * as queries from 'src/utils/queries'
import { FlexRouter, AuthRoute } from 'src/routes'
import { useToast } from 'src/utils/toast'
import { getEnvironment } from 'src/utils'
import CompanyNameHelper from 'src/connect/helpers/company-name-helper'

const ConnectSubscriptionsHeader = ({ showAdd = true, connectCustomerId, connectChannelId }) => (
  <Row justifyContent="flex-end" height={8} mb={3}>
    <Box d="inline-block" mr={2}>
      <Button
        as={Link}
        to={
          !hasPermission('connect_customers_edit') ? '' : `/connect/${getEnvironment()}/customers`
        }
        size="sm"
        type="button"
        colorScheme="secondary"
        isDisabled={!hasPermission('connect_customers_edit')}
      >
        CUSTOMERS
      </Button>
    </Box>
    <Box d="inline-block" mr={2}>
      <Button
        as={Link}
        to={
          !hasPermission('connect_channels_edit')
            ? ''
            : `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels`
        }
        size="sm"
        type="button"
        colorScheme="secondary"
        isDisabled={!hasPermission('connect_channels_edit')}
      >
        CHANNELS
      </Button>
    </Box>
    <Box>
      {showAdd && (
        <Button
          as={Link}
          to={
            !hasPermission('connect_channels_edit')
              ? ''
              : `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${connectChannelId}/subscriptions/new`
          }
          size="sm"
          type="button"
          colorScheme={!hasPermission('connect_subscriptions_edit') ? 'secondary' : 'primary'}
          isDisabled={!hasPermission('connect_subscriptions_edit')}
        >
          ADD
        </Button>
      )}
      {!showAdd && (
        <Button
          as={Link}
          to={
            !hasPermission('connect_subscriptions_edit')
              ? ''
              : `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${connectChannelId}/subscriptions`
          }
          size="sm"
          type="button"
          colorScheme="secondary"
          isDisabled={!hasPermission('connect_subscriptions_edit')}
        >
          SUBSCRIPTIONS
        </Button>
      )}
    </Box>
  </Row>
)

interface ConnectSubscriptionsProps {
  connectCustomerId: number
  connectChannelId: number
}

const ConnectSubscriptions: React.FC<ConnectSubscriptionsProps> = ({
  connectCustomerId,
  connectChannelId,
}) => {
  let connectSubscriptionsQuery = useConnectSubscriptions(getEnvironment(), connectChannelId)
  let { deleteConnectSubscription } = useConnectSubscriptionMutations()
  let connectSubscriptions = connectSubscriptionsQuery?.data
  let connectChannelsQuery = useConnectChannels(getEnvironment(), connectCustomerId)
  let connectChannels = connectChannelsQuery?.data

  let toast = useToast()
  const queryClient = useQueryClient()
  let [isUnsubscribing, setIsUnsubscribing] = React.useState(false)
  let [showUnsubscribeDialog, setShowUnsubscribeDialog] = React.useState(false)
  let [subscription, setSubscription] = React.useState(null)

  let subscribableType = connectSubscriptions?.[0]?.subscribable_type || 'company_users'

  let tableColumns = React.useMemo(() => {
    return [
      {
        id: 'id',
        accessor: 'id',
        Header: 'ID',
        disableSortBy: true,
      },
      {
        id: '_company',
        accessor: '_company',
        Header: 'Company',
        disableSortBy: false,
        Cell: ({ row }) => {
          let connectSubscription = row.original

          if (connectSubscription.subscribable_type === 'company_users') {
            return <CompanyNameHelper company_id={connectSubscription.subscribable.company_id} />
          } else {
            return <CompanyNameHelper company_id={connectSubscription.subscribable.id} />
          }
        },
      },
      // If the subscribable_type is company_users there are
      // additional columns that can be displayed.
      ...(subscribableType === 'company_users'
        ? [
            {
              id: 'full_name',
              accessor: 'subscribable.full_name',
              Header: 'Full Name',
              disableSortBy: true,
            },
            {
              id: 'account_id',
              accessor: 'subscribable.company_id_remote_id',
              Header: 'Account ID',
              disableSortBy: false,
            },
          ]
        : []),
      {
        id: 'subscribed_at',
        accessor: 'subscribed_at',
        Header: 'Subscribed At',
        Cell: ({ row }) => {
          return new Date(row.original.subscribed_at).toLocaleString()
        },
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Row justifyContent="flex-end">
              <Button
                onClick={() => {
                  setShowUnsubscribeDialog(true)
                  setSubscription(row.original)
                }}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Unsubscribe"
                isDisabled={!hasPermission('connect_subscriptions_edit')}
              >
                UNSUBSCRIBE
              </Button>
            </Row>
          )
        },
      },
    ]
  }, [subscribableType])

  if (queries.areAnyLoading(connectSubscriptionsQuery, connectChannelsQuery))
    return <Placeholders.LoadingState />
  if (queries.areAnyFailed(connectSubscriptionsQuery, connectChannelsQuery))
    return <Placeholders.FailedState />

  // relative to connect/:env/customers/:connectCustomerId/channels/:connectSubscription/subscriptions??
  return (
    <>
      <FlexRouter>
        <AuthRoute
          path="/new"
          as={SubscriptionAdd}
          connectChannels={connectChannels}
          connectSubscriptions={connectSubscriptions}
          header={
            <ConnectSubscriptionsHeader
              showAdd={false}
              connectCustomerId={connectCustomerId}
              connectChannelId={connectChannelId}
            />
          }
        />
        <AuthRoute
          default
          as={ConnectSubscriptionsTable}
          connectSubscriptions={connectSubscriptions}
          header={
            <ConnectSubscriptionsHeader
              showAdd={true}
              connectCustomerId={connectCustomerId}
              connectChannelId={connectChannelId}
            />
          }
          connectCustomerId={connectCustomerId}
          connectChannelId={connectChannelId}
          tableColumns={tableColumns}
        />
      </FlexRouter>
      <Dialog
        title="Unsubscribe"
        isOpen={showUnsubscribeDialog}
        onClose={() => setIsUnsubscribing(false)}
        actions={
          <>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                setShowUnsubscribeDialog(false)
                setSubscription(null)
              }}
              mr={2}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              colorScheme="primary"
              isLoading={isUnsubscribing}
              onClick={async () => {
                setIsUnsubscribing(true)
                await deleteConnectSubscription(subscription, {
                  onSuccess: async () => {
                    await queryClient.invalidateQueries({ queryKey: ['connect-subscriptions'] })
                    if (connectSubscriptions.length === 1) {
                      await queryClient.invalidateQueries({ queryKey: ['connect-channels'] })
                    }
                    toast({ description: 'Successfully unsubscribed' })
                    navigate(
                      `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${connectChannelId}/subscriptions`
                    )
                  },
                  onError: () => {
                    toast({ status: 'error', description: 'Failed to unsubscribe' })
                  },
                  onSettled: () => {
                    setShowUnsubscribeDialog(false)
                    setIsUnsubscribing(false)
                    setSubscription(null)
                  },
                })
              }}
            >
              Unsubscribe
            </Button>
          </>
        }
      >
        <Column>
          <Box>
            <Box>Are you sure you want to unsubsribe this subscription</Box>
          </Box>
        </Column>
      </Dialog>
    </>
  )
}

export default ConnectSubscriptions

const ConnectSubscriptionsTable = ({ connectSubscriptions, header, tableColumns }) => {
  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: connectSubscriptions,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns: tableColumns,
  })

  return (
    <>
      {header}

      {connectSubscriptions.length === 0 ? (
        <Placeholders.EmptyState message="No subscriptions found" />
      ) : (
        <Box bg="white" pt={3}>
          <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
            <Table2.Header headerGroups={headerGroups} />
            <Table2.Body
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2>
        </Box>
      )}
    </>
  )
}
