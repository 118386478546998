import { useQueryClient } from '@tanstack/react-query'
import { navigate } from '@reach/router'
import { chunk } from 'lodash'
import { filter, includes } from 'lodash/fp'
import { useToast } from 'src/utils/toast'
import { getEnvironment } from 'src/utils'
import { useBulkPositionMutations } from 'src/api/queries/positions'
import { ILocation, IPosition, ICompany } from 'src/api/api'

// Semi-arbitrary breakpoints to control max number of columns for locations
const SIZES = { LG: 950, MD: 500 }

function chunkLocations(locations: ILocation[], size: number): ILocation[][] {
  if (locations.length === 0) return []

  if (size >= SIZES.LG) {
    // Single column
    if (locations.length <= 4) return [locations]

    if (locations.length <= 20) {
      // Group into chunks of 4 when even or leaves a "widow"
      if (locations.length % 4 === 0) return chunk(locations, 4)
      if (locations.length % 5 === 1) return chunk(locations, 4)

      // Prefer chunks of 5 otherwise
      return chunk(locations, 5)
    }

    // Max of 5 columns, distribute evenly
    return splitArrayEvenly(locations, 5)
  }

  // Max of 3 columns on smaller screens
  if (size >= SIZES.MD) return splitArrayEvenly(locations, 3)

  // Only 1 column on smallest screens
  return splitArrayEvenly<ILocation>(locations, 1)
}

function splitArrayEvenly<T>(array: T[], n: number): T[][] {
  let result = []

  array = array.slice()

  while (array.length) {
    result.push(array.splice(0, Math.ceil(array.length / n--)))
  }

  return result
}

// Given a screen size, determine column width
function calcLocationColumnWidth(size: number) {
  // Max of 5 columns
  if (!size || size > SIZES.LG) return '20%'

  // Max of 3 columns
  if (size > SIZES.MD) return '33%'

  // One column
  return '100%'
}

const mapPositionLocationIds = ({
  currentPosition,
  positions,
  locations,
}: {
  currentPosition: IPosition
  positions: IPosition[]
  locations: ILocation[]
}) => {
  let remoteLocationIds = filter<IPosition>(
    {
      remote_id: currentPosition?.remote_id,
      display_name: currentPosition?.display_name,
    },
    positions
  ).map((position) => position.remote_elevator_id)

  return locations
    .filter((location) => remoteLocationIds.includes(location.remote_id.toString()))
    .map((location) => location.id)
}

interface SubmitValues {
  display_name: string
  remote_id: string
  locationIds?: number[]
}

interface usePositionFormProps {
  currentPosition?: IPosition
  company?: ICompany
  remote_id?: string
  display_name?: string
  locations?: ILocation[]
  positions?: IPosition[]
  setIsSubmitting?: (args: boolean) => void
  setIsDeleting?: (args: boolean) => void
  submitValues?: SubmitValues
  size?: number
}

const locationsEnabledForCurrentPosition = ({
  positions,
  locations,
  currentPosition,
}: {
  positions: IPosition[]
  locations: ILocation[]
  currentPosition?: IPosition
}): ILocation[] => {
  if (currentPosition) {
    const remoteLocationIds = filter<IPosition>(
      {
        remote_id: currentPosition.remote_id,
        display_name: currentPosition.display_name,
      },
      positions
    ).map((position) => position.remote_elevator_id)

    return locations.filter((location) => remoteLocationIds.includes(location.remote_id.toString()))
  }
  return []
}

export const usePositionForm = ({
  company,
  remote_id,
  currentPosition,
  positions,
  locations,
  setIsSubmitting,
  setIsDeleting,
  size,
}: usePositionFormProps) => {
  let { saveBulkPositions, deleteBulkPositions } = useBulkPositionMutations()
  let toast = useToast()
  const queryClient = useQueryClient()

  const sortedLocations = locations.sort(({ name: na }, { name: nb }) =>
    na.toLowerCase() < nb.toLowerCase() ? -1 : 1
  )
  const chunkedLocations = chunkLocations([...sortedLocations], size)

  // Create Position
  const createPositionHandler = async (submitValues: SubmitValues) => {
    if (submitValues.locationIds.length === 0) {
      toast({
        status: 'error',
        description: 'At least one location must be selected to create a position',
      })
      return
    }
    let [err, _response] = await saveBulkPositions({
      company_id: company.id,
      remote_id: submitValues.remote_id,
      display_name: submitValues.display_name,
      elevators: submitValues.locationIds,
    })
    if (err) {
      toast({
        status: 'error',
        description: 'Unable to save Positions',
      })
      setIsSubmitting(false)
      return
    }

    await queryClient.invalidateQueries({ queryKey: ['company-positions'] })
    toast({
      description: 'Successfully saved Positions',
    })
    navigate(`/companies/${getEnvironment()}/${company.slug}/positions`)
    setIsSubmitting(false)
  }

  // Update Position
  const updatePositionHandler = async (submitValues: SubmitValues) => {
    setIsSubmitting(true)

    let prevSelectedLocations = locationsEnabledForCurrentPosition({
      positions,
      locations,
      currentPosition,
    })

    let nextSelectedLocations = filter<ILocation>(
      (location) => includes(location.id, submitValues.locationIds),
      locations
    )

    let locationsToDelete = prevSelectedLocations.filter(
      (location) => !nextSelectedLocations.includes(location)
    )

    let positionsToDelete = positions
      .filter(
        (position) =>
          position.remote_id === currentPosition.remote_id &&
          locationsToDelete.some(
            (location) => location.remote_id.toString() === position.remote_elevator_id
          )
      )
      .map((position) => position.id)

    if (locationsToDelete.length > 0) {
      let [deleteErr, _deleteRes] = await deleteBulkPositions({
        remote_id,
        company_id: company.id,
        ids: positionsToDelete,
      })

      if (deleteErr) {
        toast({
          status: 'error',
          description: 'Failed to update positions',
        })
        setIsSubmitting(false)
        return
      }
    }

    let locationsToUpdate = prevSelectedLocations.filter((location) =>
      nextSelectedLocations.includes(location)
    )

    if (
      (submitValues.display_name !== currentPosition.display_name ||
        submitValues.remote_id !== currentPosition.remote_id) &&
      locationsToUpdate.length > 0
    ) {
      let positionsToUpdate = positions
        .filter(
          (position) =>
            position.display_name === currentPosition.display_name &&
            position.remote_id === currentPosition.remote_id
        )
        .map((position) => position.id)
      let [updateErr, _updateRes] = await saveBulkPositions({
        ids: positionsToUpdate,
        remote_id: submitValues.remote_id,
        display_name: submitValues.display_name,
      })

      if (updateErr) {
        toast({
          status: 'error',
          description: 'Failed to update positions',
        })
        setIsSubmitting(false)
        return
      }
    }

    let locationsToCreate = nextSelectedLocations.filter(
      (location) => !prevSelectedLocations.includes(location)
    )

    if (locationsToCreate.length > 0) {
      let [createErr, _createRes] = await saveBulkPositions({
        company_id: company.id,
        remote_id: submitValues.remote_id,
        display_name: submitValues.display_name,
        elevators: locationsToCreate.map((location) => location.id),
      })

      if (createErr) {
        toast({
          status: 'error',
          description: 'Failed to update positions',
        })
        setIsSubmitting(false)
        return
      }
    }

    await queryClient.invalidateQueries({ queryKey: ['company-positions'] })
    toast({
      description: 'Successfully updated positions',
    })
    navigate(`/companies/${getEnvironment()}/${company.slug}/positions`)
    setIsSubmitting(false)
  }

  // Delete Position
  const deletePositionHandler = async () => {
    setIsDeleting(true)

    let positionsToDelete = positions
      .filter((position) => position.remote_id === currentPosition.remote_id)
      .map((position) => position.id)

    let [err, _response] = await deleteBulkPositions({
      ids: positionsToDelete,
      remote_id: currentPosition.remote_id,
      company_id: currentPosition.company_id,
    })
    if (err) {
      toast({
        status: 'error',
        description: 'Failed to delete position',
      })
      setIsDeleting(false)
      return
    }

    await queryClient.invalidateQueries({ queryKey: ['company-positions'] })
    toast({
      description: 'Successfully deleted position',
    })
    navigate(`/companies/${getEnvironment()}/${company.slug}/positions`)
    setIsDeleting(false)
  }

  return {
    createPositionHandler,
    updatePositionHandler,
    deletePositionHandler,
    chunkedLocations,
    locationColumnWidth: calcLocationColumnWidth(size),
    currentLocationIds: mapPositionLocationIds({ currentPosition, positions, locations }),
  }
}
