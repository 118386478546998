import React from 'react'
import store from 'src/store'

/**
 * Throttles a state value.
 */
export const useThrottle = (value: any, limit: number): any => {
  let [throttledValue, setThrottledValue] = React.useState(value)
  let lastRan = React.useRef(Date.now())

  React.useEffect(() => {
    let handler = setTimeout(function () {
      if (Date.now() - lastRan.current >= limit) {
        setThrottledValue(value)
        lastRan.current = Date.now()
      }
    }, limit - (Date.now() - lastRan.current))

    return () => clearTimeout(handler)
  }, [value, limit])

  return throttledValue
}

/**
 * Debounces a state value.
 */
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay]) // Only re-call effect if value or delay changes

  return debouncedValue
}

export const getEnvironment = () => store.getState().settings.centreAPIEndpoint
