import React, { useEffect } from 'react'
import { Box, Button, Placeholders, Row, Table2, useTable } from 'src/ui'
import { hasPermission } from 'src/utils/permissions'
import { Link } from '@reach/router'
import { getEnvironment } from 'src/utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Center, Spinner } from '@chakra-ui/react'
import { useCommodityContext } from 'src/companies/commodities/v2/commodity-context'

const CommodityList = ({ permissions, header }) => {
  const { company, commodities, loadNextPage, hasNextPage, isFetching, isFetchingNextPage, error } =
    useCommodityContext()

  let columns = React.useMemo(
    () => [
      {
        id: 'display_name',
        accessor: 'displayName',
        Header: 'Display Name',
        disableSortBy: true,
      },
      {
        id: 'remote_id',
        accessor: 'sourceId',
        Header: 'Remote ID',
        disableSortBy: true,
      },
      {
        id: 'variety_name',
        accessor: 'varietyName',
        Header: 'Variety Name',
        disableSortBy: true,
        Cell: ({ row }) => {
          const commodity = row.original

          return (
            <>
              {commodity.commodityId
                ? `${commodity.className}, ${commodity.groupName}, ${commodity.subgroupName}, ${commodity.varietyName}`
                : ''}
            </>
          )
        },
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let commodity = row.original

          return (
            <Row justifyContent="flex-end">
              <Button
                as={Link}
                to={`/companies/${getEnvironment()}/${company.slug}/commodities/${
                  commodity.id
                }/edit`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Edit Commodity"
                isDisabled={!hasPermission(permissions.edit)}
              >
                EDIT
              </Button>
            </Row>
          )
        },
      },
    ],
    [permissions.edit, company.slug]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: commodities,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  // /**
  //  * This will load enough data to fill the page and make a scrollbar to appear. At that point,
  //  * react-infinite-scroll-component can handle loading additional data.
  //  */
  useEffect(() => {
    if (
      !isFetching &&
      !error &&
      hasNextPage &&
      document.getElementById('commodity_scroll_container').scrollHeight <= window.innerHeight
    ) {
      loadNextPage()
    }
  }, [isFetching, hasNextPage, loadNextPage])

  if (isFetching && !isFetchingNextPage) return <Placeholders.LoadingState />

  return (
    <>
      {header}
      <div
        id="commodity_scroll_container"
        style={{ height: 'calc(100vh - 180px', overflow: 'auto' }}
      >
        {commodities?.length === 0 ? (
          <Placeholders.EmptyState message="No commodities found" />
        ) : (
          <Box bg="white" mb={4} pt={3} borderRadius={3} boxShadow="md">
            <InfiniteScroll
              next={loadNextPage}
              hasMore={hasNextPage}
              loader={
                <Center h="50px">
                  <Spinner speed="0.8s" thickness="3px" color="inherit" />
                </Center>
              }
              dataLength={commodities?.length ?? 0}
              scrollableTarget="commodity_scroll_container"
            >
              <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
                <Table2.Header headerGroups={headerGroups} />
                <Table2.Body
                  rows={rows}
                  getTableBodyProps={getTableBodyProps}
                  prepareRow={prepareRow}
                />
              </Table2>
            </InfiniteScroll>
          </Box>
        )}
      </div>
    </>
  )
}

export default CommodityList
