import React, { FC, useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'
import { Button } from '@chakra-ui/react'
import { Formik, Form, Field, FormikProps } from 'formik'
import { Dispatch } from 'src/store'
import ScheduleList from 'src/translators/form/schedule'
import PoolList from 'src/translators/form/pool'
import JsonEditor from 'src/translators/json-editor'
import {
  Box,
  Column,
  Row,
  Text,
  ButtonLink,
  Card,
  CardContent,
  Chip,
  FormRow,
  Placeholders,
  Spinner,
  ChevronLeftIcon,
} from 'src/ui'
import {
  TextField as FormikTextField,
  JsonEditor as FormikJsonEditor,
  Select as FormikSelect,
} from 'src/ui/formik'
import {
  TranslatorConfigSchema,
  emptyConfig,
  normalizeToFormikState,
  normalizeToApiBody,
} from 'src/translators/form/utils'
import {
  STATUS,
  getImageVersions,
  getImageOptions,
  createTranslatorEnvironmentConfig,
  getTranslatorConfig,
  getLatestTranslatorConfigForEnvironment,
} from 'src/utils/api'
import { getAuthUsername } from 'src/utils/auth'
import Secret from './secret'
import { omit } from 'lodash'

const EnvironmentChip: FC<{ environment: string }> = ({ environment }) => (
  <Chip color={environment === 'prod' ? 'blue' : 'yellow'} label={environment.toUpperCase()} />
)

const TitleRow = ({ children }) => (
  <Row
    alignItems="center"
    fontWeight="bold"
    bg="#eee"
    borderRadius={3}
    height="3.5rem"
    pr={2}
    pl={3}
    mb={3}
  >
    {children}
  </Row>
)

type TranslatorFormProps = ReturnType<typeof mapDispatch> & {
  // url params from router
  slug: string
  environment: string
  translator_id: string
  // others
  translators: Translator[]
}

function TranslatorForm({
  environment,
  errorNotification,
  slug,
  successNotification,
  translators,
  translator_id,
  ...props
}: TranslatorFormProps) {
  const [status, setStatus] = useState(STATUS.loading)
  const [isEditingJson, setIsEditingJson] = useState(false)
  const [isSwitchingModes, setIsSwitchingModes] = useState(false)
  const [pendingJsonChanges, setPendingJsonChanges] = useState(null)
  const [translator, setTranslator] = useState<Translator>(() =>
    translators.find((t) => t.id === translator_id)
  )
  const [translatorConfig, setTranslatorConfig] = useState<TranslatorConfig>(null)
  const [imageOptions, setImageOptions] = useState<string[]>([])
  const [imageVersions, setImageVersions] = useState<TranslatorImage[]>([])

  // poor man's optional url parameter
  const idToCopy =
    props['*'] && props['*'].indexOf('copy/') === 0 ? props['*'].replace('copy/', '') : null

  const loadImages = useCallback(async () => {
    let [err, response] = await getImageOptions()

    if (err) {
      return console.error('failed to load images')
    }

    setImageOptions(response.data.data.sort())
  }, [])

  const loadImageVersions = useCallback(async (name: string) => {
    let [err, response] = await getImageVersions({ name })

    if (err) {
      return console.error('failed to load images')
    }

    setImageVersions(response.data.data)
  }, [])

  const setLoadedConfig = useCallback(
    (config: TranslatorConfig) => {
      if (config.adapter_module) {
        loadImageVersions(config.adapter_module)
      }

      setStatus(STATUS.loaded)
      setTranslatorConfig(config)
    },
    [loadImageVersions]
  )

  const loadLatestConfig = useCallback(async () => {
    let [err, response] = await getLatestTranslatorConfigForEnvironment({
      environment,
      translator_id,
    })

    if (err) {
      if (err.response.status === 404) {
        setStatus(STATUS.loaded)
        setTranslatorConfig(emptyConfig)
        return
      }

      return console.error('failed to load latest config')
    }

    setLoadedConfig(response.data.data)
  }, [environment, setLoadedConfig, translator_id])

  const loadConfigById = useCallback(
    async ({ id }) => {
      let [err, response] = await getTranslatorConfig({ id })

      if (err) {
        errorNotification({
          message: (
            <Column>
              <Box>
                Failed to find config <em>{id}</em>
              </Box>
              <Box>Initialized empty config</Box>
            </Column>
          ),
        })

        setLoadedConfig(emptyConfig)
        return console.error('failed to load latest config')
      }

      setLoadedConfig(response.data.data)
    },
    [errorNotification, setLoadedConfig]
  )

  const handleAuthAdapterChange = useCallback(
    (item) => {
      if (!item || !item.value) return console.warn('cannot fetch images for auth adapter', item)

      loadImageVersions(item.value)
    },
    [loadImageVersions]
  )

  const submitForm = useCallback(
    async (values) => {
      values.revision_comment = values.new_revision_comment
      values = omit(values, ['new_revision_comment'])

      let config = normalizeToApiBody(values)
      let [err] = await createTranslatorEnvironmentConfig({
        translator_id,
        environment,
        config: { ...config, user: getAuthUsername() },
      })

      if (err) {
        console.error('failed to save config', err.response.data)

        errorNotification({
          message: 'Failed to save config',
        })

        return
      }

      successNotification({
        message: 'Updated translator environment config',
      })

      navigate(`/translators/companies/${slug}`)
    },
    [environment, errorNotification, slug, successNotification, translator_id]
  )

  useEffect(() => {
    if (idToCopy) {
      loadConfigById({ id: idToCopy })
    } else {
      loadLatestConfig()
    }

    loadImages()
  }, [idToCopy, loadConfigById, loadImages, loadLatestConfig])

  useEffect(() => {
    if (!translator) {
      let loadedTranslator = translators.find((t) => t.id === translator_id)
      if (!loadedTranslator) {
        return console.error('failed to find translator in list')
      }
      setTranslator(loadedTranslator)
    }
  }, [translator, translator_id, translators])

  if (status === STATUS.idle) return null
  if (status === STATUS.loading) return <Placeholders.LoadingState />
  if (status === STATUS.failed) return <Placeholders.FailedState />

  if (!translator || !translatorConfig) return null

  return (
    <>
      <Row alignItems="center" mb={4}>
        <Box>
          <ButtonLink
            color="default"
            leftIcon={<ChevronLeftIcon />}
            to={`/translators/companies/${slug}`}
          >
            Back to List
          </ButtonLink>
        </Box>
      </Row>

      <Formik
        initialValues={normalizeToFormikState(translatorConfig)}
        validationSchema={TranslatorConfigSchema}
        onSubmit={submitForm}
      >
        {(formikProps: FormikProps<any>) => {
          if (Object.keys(formikProps.errors).length > 0) {
            console.log('validation errors', formikProps.errors)
          }

          return (
            <Form>
              <Card>
                <CardContent>
                  <Row
                    justifyContent="space-between"
                    pt={1}
                    pb={3}
                    mb={4}
                    borderBottom="1px solid #00000015"
                  >
                    <Row alignItems="center">
                      <Box pr={3}>
                        <EnvironmentChip environment={environment} />
                      </Box>

                      <Box fontWeight="bold">{translator.name}</Box>
                    </Row>

                    <Row alignItems="center">
                      {formikProps.submitCount > 0 &&
                        Object.keys(formikProps.errors).length > 0 && (
                          <Box color="#f44336" fontSize="sm" px={3}>
                            Please fix form errors
                          </Box>
                        )}

                      {isSwitchingModes && (
                        <Row px={3} alignItems="center">
                          <Spinner speed="0.65s" size="md" />
                        </Row>
                      )}

                      <Button
                        size="sm"
                        mr={3}
                        onClick={() => {
                          setIsSwitchingModes(true)
                          // setTimeout with 0 delay will clear the stack
                          // this forces a render with isSwitchingModes=true
                          // without this, the setStates get batched and we never
                          // see the spinner due to blocking
                          setTimeout(() => {
                            if (isEditingJson && pendingJsonChanges) {
                              formikProps.setValues(normalizeToFormikState(pendingJsonChanges))

                              setPendingJsonChanges(null)
                            }

                            setIsEditingJson(!isEditingJson)
                            setIsSwitchingModes(false)
                          })
                        }}
                      >
                        {isEditingJson ? 'Show Form' : 'Show JSON'}
                      </Button>

                      <Button
                        size="sm"
                        colorScheme="primary"
                        isDisabled={formikProps.isSubmitting}
                        isLoading={formikProps.isSubmitting && formikProps.values.prototype}
                        mr={3}
                        onClick={() => {
                          if (pendingJsonChanges) {
                            formikProps.setSubmitting(true)

                            submitForm(
                              normalizeToFormikState({
                                ...pendingJsonChanges,
                                prototype: true,
                              })
                            )
                          } else {
                            formikProps.setFieldValue('prototype', true, false)
                            formikProps.submitForm()
                          }
                        }}
                      >
                        Save
                      </Button>

                      <Button
                        size="sm"
                        colorScheme="primary"
                        isDisabled={formikProps.isSubmitting}
                        isLoading={formikProps.isSubmitting && !formikProps.values.prototype}
                        onClick={() => {
                          if (pendingJsonChanges) {
                            formikProps.setSubmitting(true)
                            submitForm(
                              normalizeToFormikState({
                                ...pendingJsonChanges,
                                prototype: false,
                              })
                            )
                          } else {
                            formikProps.setFieldValue('prototype', false, false)
                            formikProps.submitForm()
                          }
                        }}
                      >
                        Save &amp; Deploy
                      </Button>
                    </Row>
                  </Row>

                  {isEditingJson ? (
                    <Box>
                      <JsonEditor
                        value={normalizeToApiBody(formikProps.values)}
                        height="80vh"
                        onChange={(value) => setPendingJsonChanges(value)}
                      />
                    </Box>
                  ) : (
                    <Column>
                      <Column width="100%" borderRadius={3}>
                        <TitleRow>Config History</TitleRow>

                        <FormRow>
                          <Box fontSize="sm" color="gray.500">
                            <Text fontWeight="semibold">Previous Revision Comment</Text>
                            <Text mt={2} ml={3}>
                              {formikProps.values.revision_comment || 'null'}
                            </Text>
                          </Box>
                          <Field
                            name="new_revision_comment"
                            label="New Revision Comment"
                            component={FormikTextField}
                          />
                        </FormRow>
                      </Column>

                      <Column width="100%" borderRadius={3} mb={3}>
                        <TitleRow>Adapter</TitleRow>

                        <FormRow>
                          <Field
                            id="adapter.adapter_module"
                            name="adapter.adapter_module"
                            label="Adapter Module"
                            component={FormikSelect}
                            selectProps={{ isClearable: false }}
                            options={imageOptions.map((image) => ({
                              value: image,
                              label: image,
                            }))}
                            onChange={handleAuthAdapterChange}
                          />
                          <Field
                            id="adapter.adapter_version"
                            name="adapter.adapter_version"
                            label="Adapter Version"
                            component={FormikSelect}
                            selectProps={{ isClearable: false, sortOptions: false }}
                            options={[
                              { value: 'latest', label: 'latest' },
                              ...imageVersions.map((image) => ({
                                value: image.version,
                                label:
                                  `${image.commit_sha.substring(0, 7)} - ` +
                                  `${image.commit_message.substring(0, 32)}`,
                              })),
                            ]}
                          />
                        </FormRow>
                      </Column>

                      <Column width="100%" borderRadius={3} mb={5}>
                        <TitleRow>Auth</TitleRow>

                        <FormRow>
                          <Field
                            name="auth.adapter"
                            label="Auth Adapter"
                            component={FormikTextField}
                          />

                          <Box>&nbsp;</Box>
                        </FormRow>

                        <Column>
                          <Field
                            name="auth.adapter_config"
                            label="Auth Adapter Config"
                            component={FormikJsonEditor}
                            height={180}
                          />
                        </Column>
                      </Column>

                      <Column width="100%" borderRadius={3} mb={5}>
                        <TitleRow>Global Config</TitleRow>
                        <Box>
                          <Field name="global" component={FormikJsonEditor} height={350} />
                        </Box>
                      </Column>

                      <Column width="100%" borderRadius={3} mb={5}>
                        <TitleRow>Operations</TitleRow>
                        <Box>
                          <Field name="operations" component={FormikJsonEditor} height={180} />
                        </Box>
                      </Column>

                      <ScheduleList formikProps={formikProps} />

                      <PoolList formikProps={formikProps} />
                    </Column>
                  )}
                </CardContent>
              </Card>
              <Box mt={4}>
                <Secret translator={translator} />
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const mapDispatch = ({ notifications }: Dispatch) => ({
  successNotification: notifications.createSuccess,
  errorNotification: notifications.createError,
})

export default connect(null, mapDispatch)(TranslatorForm)
