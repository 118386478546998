import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Redirect } from '@reach/router'
import { Button } from '@chakra-ui/react'
import { Dispatch, RootState } from 'src/store'
import * as Routing from 'src/routes'
import { Box, Row, Column, Card, CardContent, Placeholders } from 'src/ui'
import { STATUS, getCompanyToken, setCompanyToken } from 'src/utils/api'
import { CompanyTokenDialog } from 'src/translators/dialogs'

const findCompany = (slug: string, companies: ConfiguratorCompany[]) =>
  companies.find((c) => c.slug === slug)

interface TokenRoutesProps {
  settings: RootState['settings']
  environments: Environment[]
  companies: ConfiguratorCompany[]
  setSettingsState: Dispatch['settings']['setState']
}

const TokenRoutes: React.FC<TokenRoutesProps> = ({
  settings,
  environments,
  companies,
  setSettingsState,
}) => {
  React.useEffect(() => {
    setSettingsState({ showCentreEnvironment: false })
  }, [setSettingsState])

  return (
    // relative to /translators/tokens/*
    <Routing.FlexRouter>
      <Routing.AuthRoute
        path="/:slug"
        as={CompanyTokens}
        environments={environments}
        companies={companies}
        settings={settings}
        setSettingsState={setSettingsState}
      />

      <Routing.AuthRoute
        default
        as={TokensDefault}
        settings={settings}
        environments={environments}
        companies={companies}
      />
    </Routing.FlexRouter>
  )
}

export default TokenRoutes

///////////////////////////////////////////////////////////////////////////////

const TokensDefault: React.FC<TokenRoutesProps> = ({ settings, companies }) => {
  let { companySlug } = settings

  if (companySlug && !!findCompany(companySlug, companies)) {
    return <Redirect noThrow to={`/translators/tokens/${companySlug}`} />
  }

  return (
    <Placeholders.EmptyState message={`Company slug "${companySlug}" not found in configurator.`} />
  )
}

///////////////////////////////////////////////////////////////////////////////

interface CompanyTokensProps {
  slug?: string // route param
  environments: Environment[]
  companies: ConfiguratorCompany[]
  settings: RootState['settings']
  setSettingsState: Dispatch['settings']['setState']
}

const CompanyTokens: React.FC<CompanyTokensProps> = ({
  slug,
  environments,
  companies,
  settings,
  setSettingsState,
}) => {
  let company = findCompany(slug, companies)

  React.useEffect(() => {
    if (settings.companySlug !== slug) setSettingsState({ companySlug: slug })
  }, [slug, settings.companySlug, setSettingsState])

  if (!company) {
    return (
      <Placeholders.FailedState message={`Company slug "${slug}" not found in configurator.`} />
    )
  }

  return (
    <Column mb={3}>
      <Row flexWrap="wrap">
        {environments.map((environment) => (
          <TokenCard
            key={`${environment.slug}${company.slug}`}
            environment={environment}
            company={company}
          />
        ))}
      </Row>
    </Column>
  )
}

///////////////////////////////////////////////////////////////////////////////

const ScrollableRow = styled(Row)`
  & {
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox
  }
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`

interface TokenCardProps {
  environment: Environment
  company: ConfiguratorCompany
}

interface TokenCardState {
  status: STATUS
  data: { [key: string]: any }
}

function TokenCard({ environment, company }: TokenCardProps) {
  const [token, setToken] = useState<TokenCardState>({
    status: STATUS.idle,
    data: null,
  })
  const [editDialog, setEditDialog] = useState(false)

  const { status, data } = token

  const handleReveal = async () => {
    let [err, response] = await getCompanyToken({
      slug: company.slug,
      environment: environment.slug,
    })

    if (err) return console.error('failed to load token')

    setToken((state) => ({
      ...state,
      status: STATUS.loaded,
      data: response.data.data,
    }))
  }

  const handleEdit = () => setEditDialog(true)

  const handleSave = async ({ token: newToken }) => {
    let [err, _] = await setCompanyToken({
      slug: company.slug,
      environment: environment.slug,
      token: newToken,
    })

    if (err) return console.error('failed to load token')

    setToken((state) => ({
      ...state,
      status: STATUS.loaded,
      data: { token: newToken },
    }))

    setEditDialog(null)
  }

  return (
    <Box mb={3} width={['100%', null, null, '50%']} px={1}>
      <Card key={environment.slug}>
        <CardContent>
          <Column>
            <Row
              justifyContent="space-between"
              alignItems="center"
              height="2.5rem"
              mb={status === STATUS.loaded ? 3 : undefined}
            >
              <Box>{environment.name}</Box>
              <Box>
                {status === STATUS.idle && (
                  <Button size="sm" variant="ghost" onClick={handleReveal}>
                    Reveal
                  </Button>
                )}
                {status === STATUS.loaded && (
                  <Button size="sm" variant="ghost" onClick={handleEdit}>
                    Edit
                  </Button>
                )}
              </Box>
            </Row>

            {status === STATUS.loaded && (
              <ScrollableRow justifyContent="center" alignItems="center" mb={3} overflow="scroll">
                <code css={{ fontSize: '0.875rem' }}>{data.token}</code>
              </ScrollableRow>
            )}
          </Column>
        </CardContent>
      </Card>

      {status === STATUS.loaded && (
        <CompanyTokenDialog
          isOpen={!!editDialog}
          onClose={() => setEditDialog(null)}
          onConfirm={handleSave}
          token={token.data.token}
          environment={environment}
        />
      )}
    </Box>
  )
}
