import React from 'react'
import { useCompanyTranslators } from 'src/api/queries/translators'
import * as Routing from 'src/routes'
import MetricDetails from './metric-details'
import MetricList from './metrics-list'
import { LS_KEYS, useLocalStorage } from 'src/utils/local-storage'

const MetricsRouter = ({ slug, setSettingsState }) => {
  const [environment, setEnvironment] = useLocalStorage(LS_KEYS.METRICS_ENV, 'prod')

  const translatorsQuery = useCompanyTranslators({ slug })
  const translatorsQueryData = translatorsQuery.data

  const translatorList = React.useMemo(
    () => translatorsQueryData?.map(({ id, name }) => ({ id, name })),
    [translatorsQueryData]
  )
  const [selectedTranslator, setSelectedTranslator] = React.useState(null)

  React.useEffect(() => {
    if (!translatorList) return
    setSelectedTranslator(translatorList[0]?.id)
  }, [translatorList])

  React.useEffect(() => {
    setSettingsState({ showCentreEnvironment: false })
  }, [setSettingsState])

  return (
    <Routing.FlexRouter>
      <Routing.AuthRoute
        path="/:scheduleName"
        as={MetricDetails}
        {...{ setSelectedTranslator, selectedTranslator, environment, slug, translatorList }}
      />

      <Routing.AuthRoute
        default
        as={MetricList}
        {...{
          setSelectedTranslator,
          selectedTranslator,
          environment,
          slug,
          translatorList,
          setEnvironment,
        }}
      />
    </Routing.FlexRouter>
  )
}
export default MetricsRouter
