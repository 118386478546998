import React from 'react'
import {
  Box,
  Row,
  Button,
  Collapse,
  ChevronLeftIcon,
  ChevronRightIcon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ChevronDownIcon,
} from 'src/ui'
import { hasPermission } from 'src/utils/permissions'
import { CASH_BID_TYPES, sortApiTypes } from './utils'
import { FormikProps } from 'formik'
import { FormValues } from './cash-bid-config-card'
import { IS_DEV_OR_SANDBOX } from 'src/utils/config'

export type MappingConfigPanelState = 'collapsed' | 'expanded' | 'editing'

interface MappingConfigPanelProps {
  bodyStyle?: any
  panelProps?: object
  hideToggleIcon?: boolean
  defaultExpanded?: boolean
  copyPermission: string
  editPermission: string
  controls?: React.ReactNode
  expanded?: boolean
  copyIsDisabled?: boolean
  panelState: MappingConfigPanelState
  formData?: any
  onChange?: (...args: any) => void
  onClickCopy?: (...args: any) => void
  onClickEdit?: (...args: any) => void
  onCancelEdit?: (...args: any) => void
  onSave?: (...args: any) => void
  displayName: string
  formikBag: FormikProps<FormValues>
}

const MappingConfigPanel: React.FC<MappingConfigPanelProps> = ({
  children,
  bodyStyle,
  panelProps,
  hideToggleIcon = false,
  defaultExpanded = false,
  copyPermission,
  editPermission,
  expanded,
  copyIsDisabled,
  panelState,
  formData,
  onChange,
  controls,
  onClickCopy,
  onClickEdit,
  onCancelEdit,
  onSave,
  displayName,
  formikBag,
}) => {
  let [innerExpanded, setInnerShow] = React.useState(defaultExpanded)
  const innerToggle = () => setInnerShow(!innerExpanded)

  let actualExpanded = typeof expanded !== 'undefined' ? expanded : innerExpanded
  const actualToggle = typeof onChange !== 'undefined' ? (e) => onChange(e, !expanded) : innerToggle

  let initialState = displayName === 'Select Config'

  return (
    <Box py={2} mb={4} borderRadius={3} bg="white" boxShadow="md" {...panelProps}>
      <Row
        justifyContent="space-between"
        alignItems="center"
        px={6}
        cursor="pointer"
        onClick={actualToggle}
      >
        <Box flex={1}>
          <Row width="100%" height="62px" justifyContent="space-between" alignItems="center">
            <Row justifyContent="space-between" alignItems="center">
              <Box color={initialState ? '#8C9BA5' : undefined} fontSize="18px">
                {displayName}
              </Box>
              {panelState === 'editing' && (
                <Box
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Menu placement="bottom-start">
                    <MenuButton
                      as={IconButton}
                      {...{
                        size: 'sm',
                        background: 'white',
                        isRound: true,
                        ml: '8px',
                      }}
                      icon={<ChevronDownIcon />}
                      css={{ textTransform: 'unset' }}
                    >
                      {displayName || <span css={{ color: '#8C9BA570' }}>Config Options</span>}
                    </MenuButton>
                    <MenuList>
                      {sortApiTypes(CASH_BID_TYPES).map((type) => {
                        return (
                          <MenuItem
                            key={type.api_type}
                            onClick={() => {
                              if (type.api_type === 'local') {
                                formikBag.setFieldValue('retrieved_from', 'local')
                                formikBag.setFieldValue('api_type', '')
                              } else {
                                formikBag.setFieldValue('retrieved_from', 'api')
                                formikBag.setFieldValue('api_type', type.api_type)
                              }
                            }}
                          >
                            {type.label}
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                  </Menu>
                </Box>
              )}
            </Row>
            {controls && !initialState && controls}
            {!controls && !initialState && (
              <>
                {panelState !== 'editing' && (
                  <Box pr={2}>
                    {hasPermission(copyPermission) && (
                      <Button
                        width="76px"
                        size="sm"
                        colorScheme="secondary"
                        mr={4}
                        isDisabled={IS_DEV_OR_SANDBOX || copyIsDisabled}
                        onClick={(e) => {
                          e.stopPropagation()
                          onClickCopy()
                        }}
                      >
                        Copy
                      </Button>
                    )}

                    {hasPermission(editPermission) && (
                      <Button
                        width="76px"
                        size="sm"
                        colorScheme="primary"
                        onClick={(e) => {
                          e.stopPropagation()
                          onClickEdit(e)
                        }}
                      >
                        EDIT
                      </Button>
                    )}
                  </Box>
                )}
              </>
            )}

            {!initialState && panelState === 'editing' && (
              <Box pr={2}>
                <Button
                  width="76px"
                  size="sm"
                  colorScheme="secondary"
                  mr={4}
                  isDisabled={formData.isSubmitting}
                  onClick={(e) => {
                    e.stopPropagation()
                    onCancelEdit(formikBag.resetForm)
                  }}
                >
                  Cancel
                </Button>

                <Button
                  width="76px"
                  size="sm"
                  colorScheme="primary"
                  isLoading={formData.isSubmitting}
                  onClick={(e) => {
                    e.stopPropagation()
                    onSave()
                  }}
                >
                  Save
                </Button>
              </Box>
            )}
          </Row>
        </Box>

        <Box>
          {!hideToggleIcon && (
            <IconButton
              icon={actualExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              aria-label="Toggle"
              isRound
              fontSize="24px"
              variant="ghost"
              color="#8C9BA5"
            />
          )}
        </Box>
      </Row>

      {!initialState && (
        <Collapse py={2} in={actualExpanded} {...bodyStyle}>
          {children}
        </Collapse>
      )}
    </Box>
  )
}

export default MappingConfigPanel
