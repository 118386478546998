import React from 'react'
import { Dispatch } from 'src/store'
import { FlexRouter, AuthRoute } from 'src/routes'
import { NewForm, EditForm } from 'src/endpoints/form'
import EndpointsList from 'src/endpoints/list'
import { useEndpoints, useFeatures, useEndpointMutations } from 'src/api/queries/endpoints'
import * as queries from 'src/utils/queries'
import { Placeholders } from 'src/ui'

type EndpointsProps = {
  environments: Environment[]
  companies: ConfiguratorCompany[]
  setSettingsState: Dispatch['settings']['setState']
}

const Endpoints: React.FC<EndpointsProps> = ({ environments, companies, setSettingsState }) => {
  React.useEffect(() => {
    setSettingsState({ showCentreEnvironment: false })
  }, [setSettingsState])

  const endpointsQuery = useEndpoints()
  const featuresQuery = useFeatures()
  let { saveEndpoint, deleteEndpoint } = useEndpointMutations()

  if (queries.areAnyLoading(endpointsQuery, featuresQuery)) {
    return <Placeholders.LoadingState />
  }

  if (queries.areAnyFailed(endpointsQuery, featuresQuery)) {
    return <Placeholders.FailedState />
  }

  return (
    <FlexRouter>
      <AuthRoute
        as={NewForm}
        path="/new"
        environments={environments}
        companies={companies}
        endpoints={endpointsQuery.data.data}
        features={featuresQuery.data.data}
        saveEndpoint={saveEndpoint}
      />
      <AuthRoute
        as={EditForm}
        path="/:id/edit"
        environments={environments}
        companies={companies}
        endpoints={endpointsQuery.data.data}
        features={featuresQuery.data.data}
        saveEndpoint={saveEndpoint}
      />
      <AuthRoute
        default
        as={EndpointsList}
        endpoints={endpointsQuery.data.data}
        features={featuresQuery.data.data}
        deleteEndpoint={deleteEndpoint}
      />
    </FlexRouter>
  )
}

export default Endpoints
