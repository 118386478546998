import React, { useEffect } from 'react'
import { Provider, connect } from 'react-redux'
import { Redirect } from '@reach/router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import store, { RootState, Dispatch } from 'src/store'
import { FlexRouter, AuthRoute, NotFound, defaultRoutePath, InvalidPermissions } from 'src/routes'
import { hasPermission, hasUserRoles } from 'src/utils/permissions'
import { GlobalStyles } from 'src/styles'
import { theme } from 'src/ui'
import Layout from 'src/layout'
import Notifications from 'src/app/notifications'
import Companies from 'src/companies'
import Reports from 'src/reports'
import Translators from 'src/translators'
import Connect from 'src/connect'
import { CreateCompanyDialog } from 'src/companies/dialogs'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ChakraProvider } from '@chakra-ui/react'

type AppProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const App: React.FC<AppProps> = ({
  settings,
  setSettingsState,
  createCompany,
  loadEnvironments,
}) => {
  let userDoesHaveRoles = hasUserRoles()

  useEffect(() => {
    if (userDoesHaveRoles) {
      loadEnvironments()
    }
  }, [loadEnvironments, userDoesHaveRoles])

  return (
    <>
      <GlobalStyles />
      <Notifications />

      <Layout>
        <FlexRouter>
          <Redirect noThrow from="/" to={defaultRoutePath()} />
          {!userDoesHaveRoles && <InvalidPermissions path="*" />}
          <AuthRoute path="/no-auth" as={() => <Redirect noThrow to="/companies" />} />
          {hasPermission('nav_company') && <AuthRoute path="/companies/*" as={Companies} />}
          {hasPermission('nav_translators') && <AuthRoute path="/translators/*" as={Translators} />}
          {hasPermission('billing_reports') && <AuthRoute path="/reports/*" as={Reports} />}
          {hasPermission('nav_connect') && <AuthRoute path="/connect/*" as={Connect} />}
          <NotFound default />
        </FlexRouter>

        <CreateCompanyDialog
          isOpen={settings.showCompanyCreateDialog}
          onClose={() => {
            setSettingsState({ showCompanyCreateDialog: false, showCompanySelectorDialog: true })
          }}
          onConfirm={(values) => createCompany({ company: values })}
        />
      </Layout>
    </>
  )
}

const mapState = ({ settings }: RootState) => ({ settings })

const mapDispatch = ({ settings, companies, environments }: Dispatch) => ({
  setSettingsState: settings.setState,
  createCompany: companies.createCompany,
  loadEnvironments: environments.loadAll,
})

const AppContainer = connect(mapState, mapDispatch)(App)

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
})

const AppProviders: React.FC = () => (
  <>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AppContainer />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </ChakraProvider>
  </>
)

export default AppProviders
