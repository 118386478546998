import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Router as ReachRouter, navigate, globalHistory, RouteComponentProps } from '@reach/router'
import { Button } from '@chakra-ui/react'
import { Box, Row, Placeholders } from 'src/ui'
import { hasPermission } from 'src/utils/permissions'
import { getKeycloakLogoutUrl } from '../utils/auth'
import { CentreEnvironment, VALID_CENTRE_ENVIRONMENTS } from 'src/api/centre'

const useLocation = () => {
  let [state, setState] = React.useState(() => ({
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  }))

  React.useEffect(() => {
    const removeListener = globalHistory.listen((params) => {
      setState((state) => ({ ...state, location: params.location }))
    })

    return () => removeListener()
  }, [])

  return state
}

export type AuthRouteProps = RouteComponentProps & {
  as: React.ComponentType
  auth: {
    token: string
    keycloak_config_url: string
  }
  [key: string]: any // maybe lock this down later
}

const NoAuth = () => (
  <Box>
    <Placeholders.FailedState message="Authentication Failure" />

    <Row justifyContent="center" mt={4}>
      <Button size="sm" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </Row>
  </Box>
)

const AuthRoute: FC<AuthRouteProps> = ({ as: RouteComponent, auth, ...rest }) => {
  if (!auth || (!auth.token && !auth.keycloak_config_url)) return <NoAuth />

  return <RouteComponent {...rest} />
}

const AuthRouteContainer = connect((state: { auth: RouteComponentProps }) => ({
  auth: state.auth,
}))(AuthRoute)

const FlexRouter: FC = ({ children }) => (
  <ReachRouter
    primary={false}
    css={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}
  >
    {children}
  </ReachRouter>
)

const NotFound: FC<RouteComponentProps> = () => (
  <Box position="absolute" top="35%" left="44%" textAlign="center" color="#aaa">
    <Box mb={3}>
      <img src="/images/empty-flat.svg" alt="empty-flat" />
    </Box>

    <Box color="#999" fontSize={36}>
      4 0 4
    </Box>
  </Box>
)

const Empty: FC<RouteComponentProps> = () => null

/**
 * The URL pattern breaks down based on `companies/` routes, or `translators/` routes.
 * Path data is parsed out and matched up based on which section we're in.
 * Companies: `/companies/:env/:slug/:route`
 * Translators: `/translators/:route/:slug`
 */
const parseRoutePath = (path: string) => {
  const [_bit0, bit1, bit2, bit3, bit4] = path.split('/')
  const isValidEnv = VALID_CENTRE_ENVIRONMENTS.includes(bit2 as CentreEnvironment)

  return {
    companies:
      bit1 === 'companies'
        ? {
            // bit0 = ""
            // bit1 = "companies"
            env: isValidEnv ? bit2 ?? null : null,
            slug: isValidEnv ? bit3 ?? null : bit2 || null,
            route: isValidEnv ? bit4 ?? null : bit3 || null,
          }
        : null,

    translators:
      bit1 === 'translators'
        ? {
            // bit0 = ""
            // bit1 = "translator"
            route: bit2 || null,
            slug: bit3 || null,
          }
        : null,

    connect:
      bit1 === 'connect'
        ? {
            // bit0 = ""
            // bit1 = "connect"
            env: bit2 || null,
            route: bit3 || null,
          }
        : null,
  }
}

/**
 * When switching environments or companies, this will redirect to the appropriate url.
 * It "parses" the current url, and then allows some passed-in args to override parts of it.
 * Kinda janky, but it works for current use-cases.
 */
const navigateAfterCompanyOrEnvChange = ({
  location,
  env,
  slug,
  route,
  setSettingsState,
}: {
  location: any
  env?: string
  slug?: string
  route?: string
  setSettingsState?: any //is there a better type for this?
}): void => {
  let { companies, translators, connect } = parseRoutePath(location.pathname)

  if (companies) {
    const nextRoute =
      '/' +
      (companies.env
        ? ['companies', env ?? companies.env, slug ?? companies.slug, route ?? companies.route]
            .filter(Boolean)
            .join('/')
        : ['companies', env, slug ?? companies.slug, route ?? companies.route]
            .filter(Boolean)
            .join('/'))

    navigate(nextRoute)
    return
  }

  if (translators && translators.route !== 'endpoints') {
    navigate(`/translators/${route || translators.route}/${slug || translators.slug || ''}`)
  }

  if (connect) {
    navigate(`/connect/${env || connect.env}/${route || connect.route}`)
    return
  }

  setSettingsState({ companySlug: slug })
}

export {
  useLocation,
  AuthRouteContainer as AuthRoute,
  FlexRouter,
  NotFound,
  Empty,
  parseRoutePath,
  navigateAfterCompanyOrEnvChange,
}

export const defaultRoutePath = () => {
  if (hasPermission('nav_company')) return '/companies'
  if (hasPermission('nav_translators')) return '/translators'
  if (hasPermission('nav_reports')) return '/reports'

  return '/companies'
}

export type RouteFunc = ({ path }: { path: string }) => JSX.Element

export const InvalidPermissions: RouteFunc = () => (
  <Box>
    <Placeholders.FailedState message="You do not have the proper permissions to access the BAP with this user." />

    <Row justifyContent="center" mt={4}>
      <Button size="sm" as="a" href={getKeycloakLogoutUrl()}>
        Sign Out
      </Button>
    </Row>
  </Box>
)
