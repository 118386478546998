import React, { useEffect } from 'react'
import { Redirect } from '@reach/router'
import moment from 'moment'
import { connect } from 'react-redux'
import { AuthRoute, FlexRouter } from 'src/routes'
import LoggedInUsersReport from 'src/reports/logged-in-users'
import EsignReport from 'src/reports/esign'
import { Dispatch } from 'src/store'
import { CompaniesState } from 'src/store/models/companies'
import { SettingsState } from 'src/store/models/settings'
import { Placeholders } from 'src/ui'
import * as api from 'src/utils/api'
import ProofOfYield from 'src/reports/proof-of-yield'
import Tickets from 'src/reports/ticket-applications'
import Contracts from 'src/reports/contracts'

type ReportsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const ReportsIndex = ({ companies, loadCompanies, settings, setSettingsState }: ReportsProps) => {
  useEffect(() => {
    if (companies.status === api.STATUS.idle) loadCompanies()
    setSettingsState({ showCentreEnvironment: true })
  }, [companies.status, loadCompanies, setSettingsState])

  if (companies.status === api.STATUS.idle) return <Placeholders.LoadingState />
  if (companies.status === api.STATUS.loading) return <Placeholders.LoadingState />
  if (companies.status === api.STATUS.failed) return <Placeholders.FailedState />

  const defaultDate = moment().subtract(1, 'month').format('YYYY-MM')

  return (
    <FlexRouter>
      <Redirect noThrow from="/" to={`/reports/logged-in-users/${defaultDate}`} />
      <Redirect noThrow from="/logged-in-users" to={`/reports/logged-in-users/${defaultDate}`} />
      <Redirect noThrow from="/esign" to={`/reports/esign/${defaultDate}`} />

      <AuthRoute path="/contracts" as={Contracts} companies={companies.data} settings={settings} />
      <AuthRoute
        path="/proof-of-yield"
        as={ProofOfYield}
        companies={companies.data}
        settings={settings}
      />
      <AuthRoute
        path="/ticket-applications"
        as={Tickets}
        companies={companies.data}
        settings={settings}
      />
      <AuthRoute
        path="/logged-in-users/:date"
        as={LoggedInUsersReport}
        companies={companies.data}
        settings={settings}
        defaultDate={defaultDate}
      />
      <AuthRoute
        path="/esign/:date"
        as={EsignReport}
        companies={companies.data}
        settings={settings}
        defaultDate={defaultDate}
      />
    </FlexRouter>
  )
}

const mapState = (state: { companies: CompaniesState; settings: SettingsState }) => ({
  companies: state.companies,
  settings: state.settings,
})

const mapDispatch = ({ settings, companies }: Dispatch) => ({
  loadCompanies: companies.loadAll,
  setSettingsState: settings.setState,
})

export default connect(mapState, mapDispatch)(ReportsIndex)
