import React from 'react'
import { Button, Collapse, Select, Stack } from '@chakra-ui/react'
import { getMetrics, getCompanyToken } from 'src/utils/api'
import { Box, Card, ChevronDownIcon, ChevronUpIcon, IconButton } from 'src/ui'
import moment from 'moment'
import { navigate } from '@reach/router'
import { DetailLabel, DetailValue } from './ui'
import { ICompany } from 'src/api/api'
import { translatorEnvironmentUrl } from 'src/translators/utils'

const MetricList = ({
  setEnvironment,
  environment,
  slug,
  selectedTranslator,
  setSelectedTranslator,
  translatorList,
}) => {
  const [data, setData] = React.useState<any>()

  React.useEffect(() => {
    const getListMetrics = async () => {
      if (!selectedTranslator) return
      const [, res] = await getCompanyToken({
        slug: slug,
        environment: environment,
      })
      const adapter_url = translatorEnvironmentUrl({
        translator_id: selectedTranslator,
        environment_slug: environment,
      })
      const api_token = res?.data?.data?.token
      const [, metricsRes] = await getMetrics({ adapter_url, api_token })
      setData(metricsRes?.data)
    }

    getListMetrics()
  }, [selectedTranslator, environment, slug])

  return (
    <>
      <Box d="flex" flexDir="column">
        <Stack isInline spacing={4} alignSelf="flex-end" mb={4}>
          <Box maxW={300}>
            {translatorList?.length > 1 && (
              <TranslatorSelector
                onChange={(e) => setSelectedTranslator(e.currentTarget.value)}
                {...{ translator: selectedTranslator, translatorList }}
              />
            )}
          </Box>

          <Box maxW={300}>
            <EnvironmentSelector
              onChange={(e) => {
                setEnvironment(e.currentTarget.value)
              }}
              environment={environment}
            />
          </Box>
        </Stack>

        {!!data &&
          [...data]
            .sort((a, b) => {
              // If `lastFailure` exists and it's newer than `lastSuccess` == error state
              const aHasFailure = a.lastFailure && a.lastFailure > (a.lastSuccess || '0')
              const bHasFailure = b.lastFailure && b.lastFailure > (b.lastSuccess || '0')
              // No change if they're both failed or successful
              if (aHasFailure === bHasFailure) return 0
              // One of 'em has a failure, order appropriately
              return aHasFailure ? -1 : 1
            })
            .map((metric, index) => (
              <Card mb={3} key={index}>
                <MetricListItem companyMetric={metric} {...{ slug }} />
              </Card>
            ))}
      </Box>
    </>
  )
}
export default MetricList

type CompanyMetricsProps = {
  companyMetric: CompanyListMetric
  slug: ICompany['slug']
}

const MetricListItem: React.FC<CompanyMetricsProps> = ({ companyMetric, slug }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  return (
    <Box>
      <Box
        d="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <Box d="flex" alignItems="center">
          <Box
            backgroundColor={
              companyMetric.lastFailure === undefined
                ? 'green.500'
                : companyMetric.lastSuccess === undefined
                ? 'red.500'
                : companyMetric.lastFailure > companyMetric.lastSuccess
                ? 'red.500'
                : 'green.500'
            }
            w=".5rem"
            h=".5rem"
            borderRadius="100%"
            mr={2}
          ></Box>
          <Box>{companyMetric.name}</Box>
        </Box>

        <Box d="flex" alignItems="center">
          {isExpanded ? (
            <Box fontSize="0.875rem" color="blue.500" onClick={() => {}}>
              <Button
                variant="link"
                onClick={() => {
                  navigate(`/translators/metrics/${slug}/${encodeURIComponent(companyMetric.name)}`)
                }}
                _hover={{}}
                color="blue.500"
              >
                View Detail
              </Button>
            </Box>
          ) : null}
          <IconButton
            icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            aria-label=""
            size="lg"
            variant="ghost"
            isRound
            color="gray.600"
          />
        </Box>
      </Box>

      <Collapse in={isExpanded}>
        <Stack isInline spacing={4} align="center" height="80px" px={6}>
          <Box flex={1} minWidth={0}>
            <Box height="62px">
              <DetailLabel label="Last Success" />
              <DetailValue
                value={
                  !!companyMetric.lastSuccess
                    ? moment(companyMetric.lastSuccess).format('LLL')
                    : undefined
                }
                title={
                  !!companyMetric.lastSuccess
                    ? moment(companyMetric.lastSuccess).from(new Date())
                    : undefined
                }
              />
            </Box>
          </Box>
          <Box flex={1} minWidth={0}>
            <Box height="62px">
              <DetailLabel label="Last Run" />
              <DetailValue
                value={
                  !!companyMetric.lastRun ? moment(companyMetric.lastRun).format('LLL') : undefined
                }
                title={
                  !!companyMetric.lastRun
                    ? moment(companyMetric.lastRun).from(new Date())
                    : undefined
                }
              />
            </Box>
          </Box>
          <Box flex={1} minWidth={0}>
            <Box height="62px">
              <DetailLabel label="Last Run Duration" />
              <DetailValue
                value={
                  !!companyMetric.lastRunDuration
                    ? `${(companyMetric.lastRunDuration / 1000).toFixed(2)} Seconds`
                    : undefined
                }
                title={
                  !!companyMetric.lastRunDuration
                    ? `${(companyMetric.lastRunDuration / 1000).toFixed(2)} Seconds`
                    : undefined
                }
              />
            </Box>
          </Box>
          <Box flex={1} minWidth={0}>
            <Box height="62px">
              <DetailLabel label="Last Failure" />
              <DetailValue
                value={
                  !!companyMetric.lastFailure
                    ? moment(companyMetric.lastFailure).format('LLL')
                    : undefined
                }
                title={
                  !!companyMetric.lastFailure
                    ? moment(companyMetric.lastFailure).from(new Date())
                    : undefined
                }
              />
            </Box>
          </Box>
        </Stack>

        <Stack isInline spacing={4} align="center" height="80px" px={6} bg="gray.50">
          <Box flex={1} minWidth={0}>
            <Box height="62px">
              <DetailLabel label="Average Run Time" />
              <DetailValue
                value={
                  !!companyMetric.averageRunTime
                    ? `${(companyMetric.averageRunTime / 1000).toFixed(2)} Seconds`
                    : undefined
                }
                title={
                  !!companyMetric.averageRunTime
                    ? `${(companyMetric.averageRunTime / 1000).toFixed(2)} Seconds`
                    : undefined
                }
              />
            </Box>
          </Box>
          <Box flex={1} minWidth={0}>
            <Box height="62px">
              <DetailLabel label="Time to Repull" />
              <DetailValue value={companyMetric.timeToRepull} title={companyMetric.timeToRepull} />
            </Box>
          </Box>
          <Box flex={1} minWidth={0}>
            <Box height="62px">
              <DetailLabel label="Last Error Message" />
              <DetailValue
                value={companyMetric.lastErrorMessage}
                title={companyMetric.lastErrorMessage}
              />
            </Box>
          </Box>
          <Box flex={1}></Box>
        </Stack>
      </Collapse>
    </Box>
  )
}

const TranslatorSelector = ({ onChange, translator, translatorList }) => {
  return (
    <Select value={translator} onChange={onChange}>
      {translatorList?.map((translator) => (
        <option value={translator.id} key={translator.id}>
          {translator.name}
        </option>
      ))}
    </Select>
  )
}

const EnvironmentSelector = ({ onChange, environment }) => {
  return (
    <Select value={environment} onChange={onChange} bg="white">
      <option value="prod">Production</option>
      <option value="uat">UAT</option>
    </Select>
  )
}
