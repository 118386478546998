import React, { useEffect, useState } from 'react'
import { Redirect } from '@reach/router'
import { Dispatch, RootState } from 'src/store'
import { AuthRoute, FlexRouter } from 'src/routes'
import { DiffDialog } from 'src/companies/dialogs'
import CompanyConfigurations from 'src/companies/configurations'
import CompanyTranslator from 'src/companies/translator'
import CompanyLocations from 'src/companies/locations'
import CompanyCashBids from 'src/companies/cashbids'
import CompanyPositions from 'src/companies/positions'
import OfferManagementSystem from 'src/companies/oms'
import CommoditiesRouter from 'src/companies/commodities/router'

export * from 'src/companies/routes/companies-default'
export * from 'src/companies/routes/helpers'
export * from 'src/companies/routes/validators'

interface CompanyRoutesProps {
  env: string
  slug: string
  company: Company
  companies: RootState['companies']
  setSettingsState: Dispatch['settings']['setState']
}

interface CompanyRoutesState {
  copyDialog: any
}

function CompanyRoutes({ env, slug, company, companies, setSettingsState }: CompanyRoutesProps) {
  useEffect(() => {
    setSettingsState({ showCompanySelectorDialog: true, showCentreEnvironment: true })
  }, [setSettingsState])

  const [copyDialog, setCopyDialog] = useState<CompanyRoutesState>(null)
  return (
    // relative to /companies/:env/:slug/*
    <>
      <FlexRouter>
        <AuthRoute
          path="/oms"
          as={OfferManagementSystem}
          company={company}
          companies={companies.data}
          setCopyDialog={setCopyDialog}
        />
        <AuthRoute
          path="/configurations"
          as={CompanyConfigurations}
          company={company}
          companies={companies.data}
          setCopyDialog={setCopyDialog}
        />

        <AuthRoute
          path="/translator"
          as={CompanyTranslator}
          company={company}
          companies={companies.data}
          setCopyDialog={setCopyDialog}
        />

        <AuthRoute
          path="/locations"
          as={CompanyLocations}
          company={company}
          companies={companies.data}
          setCopyDialog={setCopyDialog}
        />

        <AuthRoute
          path="/cash-bid-config"
          as={CompanyCashBids}
          company={company}
          companies={companies.data}
          setCopyDialog={setCopyDialog}
        />

        <AuthRoute
          path="/positions/*"
          as={CompanyPositions}
          company={company}
          setCopyDialog={setCopyDialog}
        />

        <AuthRoute
          path="/commodities/*"
          as={CommoditiesRouter}
          company={company}
          companies={companies.data}
          setCopyDialog={setCopyDialog}
          env={env}
        />

        <AuthRoute
          default
          as={({ env, slug }) => (
            <Redirect noThrow to={`/companies/${env}/${slug}/configurations`} />
          )}
          env={env}
          slug={slug}
        />
      </FlexRouter>

      <DiffDialog
        isOpen={!!copyDialog}
        onClose={() => setCopyDialog(null)}
        onConfirm={() => setCopyDialog(null)}
        {...copyDialog}
      />
    </>
  )
}

export default CompanyRoutes
