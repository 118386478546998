import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from '@reach/router'
import { RootState, Dispatch } from 'src/store'
import { FlexRouter, AuthRoute, Empty } from 'src/routes'
import CompanyTranslators from 'src/translators/company-translators'
import CompanyTokens from 'src/translators/company-tokens'
import Endpoints from 'src/endpoints'
import Metrics from 'src/metrics'
import { Placeholders } from 'src/ui'
import * as api from 'src/utils/api'

interface TranslatorsDefaultProps {
  settings: RootState['settings']
  companies: Company[]
  setSettingsState: Dispatch['settings']['setState']
}

function TranslatorsDefault({ settings, companies, setSettingsState }: TranslatorsDefaultProps) {
  const { companySlug } = settings
  useEffect(() => {
    if (!companySlug || !companies.find((c) => c.slug === companySlug)) {
      setSettingsState({ showCompanySelectorDialog: true, showCentreEnvironment: false })
    }
  }, [companies, companySlug, setSettingsState])

  if (companySlug && companies.find((c) => c.slug === companySlug)) {
    return <Redirect noThrow to={`/translators/companies/${companySlug}`} />
  }

  return <Empty />
}

///////////////////////////////////////////////////////////////////////////////

type TranslatorsRoutesProps = {
  slug?: string // route url param
  settings: RootState['settings']
  setSettingsState: Dispatch['settings']['setState']
  environments: Environment[]
  configuratorCompanies: ConfiguratorCompany[]
}

function TranslatorRoutes({
  settings,
  environments,
  configuratorCompanies,
  setSettingsState,
}: TranslatorsRoutesProps) {
  return (
    // relative to /translators/*
    <FlexRouter>
      <AuthRoute
        path="/tokens/*"
        as={CompanyTokens}
        settings={settings}
        setSettingsState={setSettingsState}
        environments={environments}
        companies={configuratorCompanies}
      />
      <AuthRoute
        path="/endpoints/*"
        as={Endpoints}
        setSettingsState={setSettingsState}
        environments={environments}
        companies={configuratorCompanies}
      />
      <AuthRoute path="/metrics/:slug/*" as={Metrics} setSettingsState={setSettingsState} />
      <AuthRoute
        path="/companies/:slug/*"
        as={CompanyTranslators}
        environments={environments}
        companies={configuratorCompanies}
      />
      <AuthRoute
        default
        as={TranslatorsDefault}
        settings={settings}
        setSettingsState={setSettingsState}
        companies={configuratorCompanies}
      />
    </FlexRouter>
  )
}

///////////////////////////////////////////////////////////////////////////////

type TranslatorsContainerProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> & {
    slug?: string // route url param
  }

function TranslatorsContainer({
  centreCompanies,
  configuratorCompanies,
  environments,
  loadCentreCompanies,
  loadConfiguratorCompanies,
  setSettingsState,
  settings,
}: TranslatorsContainerProps) {
  useEffect(() => {
    if (centreCompanies.status === api.STATUS.idle) loadCentreCompanies()
    loadConfiguratorCompanies()
  }, [centreCompanies.status, loadCentreCompanies, loadConfiguratorCompanies])

  if (environments.status !== api.STATUS.loaded) return null
  if (configuratorCompanies.status === api.STATUS.idle) return null
  if (centreCompanies.status === api.STATUS.loading) return <Placeholders.LoadingState />

  return (
    <>
      {configuratorCompanies.status === api.STATUS.loading && <Placeholders.LoadingState />}
      {configuratorCompanies.status === api.STATUS.failed && <Placeholders.FailedState />}
      {configuratorCompanies.status === api.STATUS.loaded && (
        <TranslatorRoutes
          settings={settings}
          setSettingsState={setSettingsState}
          environments={environments.data}
          configuratorCompanies={configuratorCompanies.data}
        />
      )}
    </>
  )
}

const mapState = ({ settings, environments, companies, configuratorCompanies }: RootState) => ({
  settings,
  environments,
  centreCompanies: companies,
  configuratorCompanies,
})
const mapDispatch = ({ settings, companies, configuratorCompanies }: Dispatch) => ({
  setSettingsState: settings.setState,
  loadCentreCompanies: companies.loadAll,
  loadConfiguratorCompanies: configuratorCompanies.loadAll,
})

export default connect(mapState, mapDispatch)(TranslatorsContainer)
