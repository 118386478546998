import React, { useCallback, useMemo } from 'react'
import { RootState, Dispatch } from 'src/store'
import { useLocation, navigateAfterCompanyOrEnvChange } from 'src/routes'
import { Box, Row, Button, Menu, MenuButton, MenuList, MenuItem, ChevronDownIcon } from 'src/ui'
import Logo from 'src/ui/logo-u'
import { UserInfoButton } from 'src/layout/header/user-info-button'
import { CONFIG } from 'src/utils/config'
import { CentreDevEnvConfig, CENTRE_DEV_ENVIRONMENTS, getEnvLabel } from 'src/api/centre'

interface HeaderProps {
  settings: RootState['settings']
  resetCompanies: Dispatch['companies']['reset']
  setSettingsState: Dispatch['settings']['setState']
}

export function Header({ settings, resetCompanies, setSettingsState }: HeaderProps) {
  const { location } = useLocation()

  const handleEnvironmentChange = useCallback(
    (environment: CentreDevEnvConfig) => {
      setSettingsState({ centreAPIEndpoint: environment.short })
      resetCompanies()
      navigateAfterCompanyOrEnvChange({ location, env: environment.short })
    },
    [location, resetCompanies, setSettingsState]
  )

  return (
    <Row
      className="page-header"
      position="fixed"
      alignItems="center"
      width="100%"
      height="60px"
      bg={getHeaderBackgroundColor(settings)}
      zIndex={2}
      px={6}
    >
      <Row color="white" alignItems="center">
        <Logo />
      </Row>

      <Row flex={1} justifyContent="flex-end">
        <Box>
          {settings.showCentreEnvironment && (
            <EnvironmentPicker
              settings={settings}
              handleEnvironmentChange={handleEnvironmentChange}
            />
          )}
        </Box>

        <UserInfoButton />
      </Row>
    </Row>
  )
}

const HEADER_BG_DEV = '#F4BD27' // yellow
const HEADER_BG_PROD = '#291535' // plum

function getHeaderBackgroundColor({
  showCentreEnvironment,
  centreAPIEndpoint,
}: RootState['settings']) {
  // If we're not interacting with a Centre-based page, keep it "production"
  if (!showCentreEnvironment) return HEADER_BG_PROD

  const isCentreProduction = CONFIG.bushel_env === 'prod' && centreAPIEndpoint === 'production'

  return isCentreProduction ? HEADER_BG_PROD : HEADER_BG_DEV
}

function EnvironmentPicker({ settings, handleEnvironmentChange }) {
  const { envOptions, currentEnvOption } = usePickerOptions({ settings })

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        textTransform="none"
        colorScheme="white"
      >
        {currentEnvOption?.label ?? 'Select Centre Environment'}
      </MenuButton>

      <MenuList
        minWidth="150px"
        css={{
          button: {
            textTransform: 'none',
          },
        }}
      >
        {envOptions.map((option) => (
          <MenuItem
            key={option.short}
            fontWeight={settings.centreAPIEndpoint === option.short ? 600 : undefined}
            onClick={() => handleEnvironmentChange(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

function usePickerOptions({ settings }) {
  const envOptions = useMemo(() => {
    if (CONFIG.bushel_env === 'sandbox') {
      return [{ short: 'sandbox', label: getEnvLabel(CONFIG.centre_prod_api_url) }]
    }

    if (CONFIG.bushel_env === 'prod') {
      return [
        { short: 'uat', label: getEnvLabel(CONFIG.centre_uat_api_url) },
        { short: 'production', label: getEnvLabel(CONFIG.centre_prod_api_url) },
      ]
    }

    return CENTRE_DEV_ENVIRONMENTS
  }, [])

  const currentEnvOption = envOptions.find((opt) => opt.short === settings.centreAPIEndpoint)

  return {
    envOptions,
    currentEnvOption,
  }
}
