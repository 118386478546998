import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState, Dispatch } from 'src/store'
import { FlexRouter, AuthRoute } from 'src/routes'
import * as api from 'src/utils/api'
import { Placeholders } from 'src/ui'
import {
  CompaniesDefault,
  ValidatedBuildManagerRoutes,
  ValidatedCompanyBranding,
  ValidatedCompanyRoutes,
} from 'src/companies/routes'

export type CompaniesProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const Companies = ({
  settings,
  companies,
  loadCompanies,
  resetCompanies,
  setSettingsState,
}: CompaniesProps) => {
  useEffect(() => {
    if (companies.status === api.STATUS.idle) loadCompanies()
  }, [companies.status, loadCompanies])

  if (companies.status === api.STATUS.failed) return <Placeholders.FailedState />
  if (companies.status === api.STATUS.loading) return <Placeholders.LoadingState />
  if (companies.status === api.STATUS.idle) return <Placeholders.LoadingState />
  if (companies.status !== api.STATUS.loaded) return null

  return (
    // relative to /companies/*
    <>
      <FlexRouter>
        <AuthRoute
          path="/:slug/build-manager/*"
          as={ValidatedBuildManagerRoutes}
          settings={settings}
          companies={companies}
          loadCompanies={loadCompanies}
          setSettingsState={setSettingsState}
        />

        <AuthRoute
          path="/:slug/branding"
          as={ValidatedCompanyBranding}
          settings={settings}
          companies={companies}
          loadCompanies={loadCompanies}
          setSettingsState={setSettingsState}
        />

        <AuthRoute
          path="/:env/:slug/*"
          as={ValidatedCompanyRoutes}
          settings={settings}
          companies={companies}
          loadCompanies={loadCompanies}
          resetCompanies={resetCompanies}
          setSettingsState={setSettingsState}
        />

        <AuthRoute
          path="/:env"
          as={CompaniesDefault}
          settings={settings}
          companies={companies}
          loadCompanies={loadCompanies}
          resetCompanies={resetCompanies}
          setSettingsState={setSettingsState}
        />

        <AuthRoute
          default
          as={CompaniesDefault}
          settings={settings}
          companies={companies}
          loadCompanies={loadCompanies}
          resetCompanies={resetCompanies}
          setSettingsState={setSettingsState}
        />
      </FlexRouter>
    </>
  )
}

const mapState = ({ settings, companies }: RootState) => ({ settings, companies })
const mapDispatch = ({ settings, companies }: Dispatch) => ({
  setSettingsState: settings.setState,
  loadCompanies: companies.loadAll,
  resetCompanies: companies.reset,
})

export default connect(mapState, mapDispatch)(Companies)
