import React from 'react'
import { navigate } from '@reach/router'
import { useQueryClient } from '@tanstack/react-query'
import styled from '@emotion/styled'
import { Button } from '@chakra-ui/react'
import { DeleteDialog } from 'src/endpoints/dialogs'
import { randKey } from 'src/translators/form/utils'
import { Box, Column, Row, Card, CardContent, IconButton, Tooltip, DeleteIcon } from 'src/ui'
import { useToast } from 'src/utils/toast'

const ListCard = styled(Card)({ cursor: 'pointer' })

interface EndpointProps {
  endpoint: Endpoint
  showDeleteDialog: () => void
}

const EndpointCard: React.FC<EndpointProps> = ({ endpoint, showDeleteDialog }) => {
  let { id, url, subscriptions } = endpoint

  return (
    <ListCard mb={3} onClick={() => navigate(`/translators/endpoints/${id}/edit`)}>
      <CardContent>
        <Column>
          <Row justifyContent="space-between" alignItems="center">
            <Box flex="1">{url}</Box>

            <Box px={4} fontSize="sm">
              <em>{subscriptions ? subscriptions.length : 0} subscription(s)</em>
            </Box>

            <Box px={2}>
              <Tooltip label="Delete Endpoint" aria-label="Delete Endpoint" placement="left">
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete Endpoint"
                  size="lg"
                  variant="ghost"
                  isRound
                  color="gray.600"
                  onClick={(e) => {
                    e.stopPropagation()
                    showDeleteDialog()
                  }}
                />
              </Tooltip>
            </Box>
          </Row>
        </Column>
      </CardContent>
    </ListCard>
  )
}

///////////////////////////////////////////////////////////////////////////////

interface EndpointsProps {
  slug: string
  endpoints: Endpoint[]
  deleteEndpoint: any
}

const EndpointList: React.FC<EndpointsProps> = ({ endpoints, deleteEndpoint }) => {
  let toast = useToast()
  const queryClient = useQueryClient()
  let [deleteDialog, setDeleteDialog] = React.useState(null)

  if (!endpoints) return null

  return (
    <>
      <Row alignItems="center" justifyContent="flex-end" mb={3}>
        <Box>
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() => navigate('/translators/endpoints/new')}
          >
            Create Endpoint
          </Button>
        </Box>
      </Row>

      {endpoints.length > 0 &&
        endpoints.map((endpoint) => (
          <EndpointCard
            key={endpoint.id}
            endpoint={{
              ...endpoint,
              subscriptions: endpoint.subscriptions.map((e) => ({ ...e, key: randKey() })),
            }}
            showDeleteDialog={() => setDeleteDialog({ endpoint })}
          />
        ))}

      <DeleteDialog
        isOpen={!!deleteDialog}
        onClose={() => setDeleteDialog(null)}
        componentTitle="Endpoint"
        onConfirm={async () => {
          await deleteEndpoint(
            { endpoint: deleteDialog.endpoint },
            {
              onSuccess: async () => {
                setDeleteDialog(false)
                await queryClient.invalidateQueries({ queryKey: ['endpoints'] })
                toast({
                  description: 'Successfully removed endpoint',
                })
              },
              onError: () => {
                setDeleteDialog(false)
                toast({
                  status: 'error',
                  description: 'Failed to remove endpoint',
                })
              },
            }
          )
        }}
      />
    </>
  )
}

export default EndpointList
