import React from 'react'
import { navigate } from '@reach/router'
import { useQueryClient } from '@tanstack/react-query'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { NotFound } from 'src/routes'
import SubscriptionList from 'src/endpoints/form/subscription-list'
import { TextField as FormikTextField } from 'src/ui/formik'
import { Box, Column, Row, Card, CardContent, FormRow, Button, ChevronLeftIcon } from 'src/ui'
import { useToast } from 'src/utils/toast'

const EndpointSchema = Yup.object().shape({
  url: Yup.string().url('Invalid URL').required('Required'),
  token: Yup.string().required('Required'),
})

interface FormProps {
  endpoint?: Endpoint
  endpoints: Endpoint[]
  environments: Environment[]
  companies: ConfiguratorCompany[]
  features: string[]
  saveEndpoint: (
    args: { endpoint: Endpoint },
    options: {
      onSuccess: (
        [_err, { data }]: [any, { data: any }],
        { endpoint }: { endpoint: Endpoint }
      ) => void
      onError: () => any
    }
  ) => Promise<any>
}

interface FormValues {
  url: string
  token: string
}

const EndpointForm: React.FC<FormProps> = ({
  endpoint,
  environments,
  companies,
  features,
  saveEndpoint,
}) => {
  let toast = useToast()
  const queryClient = useQueryClient()
  let [isLoading, setIsLoading] = React.useState(false)

  return (
    <>
      <Row alignItems="center" mb={4}>
        <Box>
          <Button
            size="sm"
            leftIcon={<ChevronLeftIcon />}
            onClick={() => navigate('/translators/endpoints')}
          >
            Back to List
          </Button>
        </Box>
      </Row>

      <Card mb={3}>
        <CardContent>
          <Column>
            <Row alignItems="center" fontWeight="bold" mb={4}>
              <Box ml={2}>Endpoint</Box>
            </Row>

            <Formik
              initialValues={endpoint}
              validationSchema={EndpointSchema}
              onSubmit={async (values) => {
                setIsLoading(true)
                await saveEndpoint(
                  { endpoint: values },
                  {
                    onSuccess: async ([_err, { data }], { endpoint }) => {
                      await queryClient.invalidateQueries({ queryKey: ['endpoints'] })
                      toast({
                        description: `Successfully ${endpoint.id ? 'updated' : 'created'} endpoint`,
                      })
                      setIsLoading(false)
                      navigate(
                        `/translators/endpoints/${endpoint.id ? endpoint.id : data.data.id}/edit`
                      )
                    },
                    onError: () => {
                      toast({ status: 'error', description: 'Failed to save endpoint' })
                      setIsLoading(false)
                    },
                  }
                )
              }}
            >
              {(formikProps: FormikProps<FormValues>) => (
                <Form>
                  <FormRow>
                    <Field name="url" label="URL" component={FormikTextField} />
                  </FormRow>

                  <FormRow>
                    <Field name="token" label="Token" component={FormikTextField} />
                  </FormRow>

                  <Row py={2} justifyContent="flex-end">
                    <Box mx={2}>
                      <Button
                        size="sm"
                        isDisabled={!formikProps.dirty}
                        onClick={() => navigate('/translators/endpoints')}
                      >
                        Cancel
                      </Button>
                    </Box>

                    <Box mx={2}>
                      <Button
                        type="submit"
                        size="sm"
                        colorScheme="primary"
                        isDisabled={!formikProps.dirty}
                        isLoading={isLoading}
                      >
                        Save
                      </Button>
                    </Box>
                  </Row>
                </Form>
              )}
            </Formik>

            {endpoint.id && (
              // do not show subscriptions until the endpoint itself is created
              <SubscriptionList
                endpoint={endpoint}
                companies={companies}
                features={features}
                environments={environments}
                subscriptions={endpoint.subscriptions}
              />
            )}
          </Column>
        </CardContent>
      </Card>
    </>
  )
}

export const NewForm: React.FC<FormProps> = (props) => (
  <EndpointForm
    {...props}
    endpoint={{
      id: null,
      url: '',
      token: '',
      subscriptions: [],
    }}
  />
)

type EditFormProps = FormProps & {
  id: string // from route
}

export const EditForm: React.FC<EditFormProps> = (props) => {
  let endpoint = props.endpoints.find((e) => e.id === props.id)

  if (!endpoint) return <NotFound />

  return <EndpointForm {...props} endpoint={endpoint} />
}
