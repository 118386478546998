import React from 'react'
import { Box } from '@chakra-ui/react'

export const CardContent: React.FC = ({ children }) => <>{children}</>

const Card = ({ children, ...props }) => (
  <Box p={4} borderRadius={3} bg="white" boxShadow="md" {...props}>
    {children}
  </Box>
)

export default Card
