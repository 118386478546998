import React from 'react'
import { useConnectUsers } from 'src/api/queries/connect-users'
import { Link } from '@reach/router'
import { hasPermission } from 'src/utils/permissions'
import { Box, Button, Row, Placeholders, Table2, useTable } from 'src/ui'
import { UserForm } from 'src/connect/users/user-editor'
import * as queries from 'src/utils/queries'
import { FlexRouter, AuthRoute } from 'src/routes'
import { getEnvironment } from 'src/utils'

const ConnectUsersHeader = ({ showAdd = true, connectCustomerId, connectChannelId }) => (
  <Row justifyContent="flex-end" height={8} mb={3}>
    <Box d="inline-block" mr={2}>
      <Button
        as={Link}
        to={
          !hasPermission('connect_customers_edit') ? '' : `/connect/${getEnvironment()}/customers`
        }
        size="sm"
        type="button"
        colorScheme="secondary"
        isDisabled={!hasPermission('connect_customers_edit')}
      >
        CUSTOMERS
      </Button>
    </Box>
    <Box d="inline-block" mr={2}>
      <Button
        as={Link}
        to={
          !hasPermission('connect_channels_edit')
            ? ''
            : `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels`
        }
        size="sm"
        type="button"
        colorScheme="secondary"
        isDisabled={!hasPermission('connect_channels_edit')}
      >
        CHANNELS
      </Button>
    </Box>
    <Box>
      {showAdd && (
        <Button
          as={Link}
          to={
            !hasPermission('connect_users_edit')
              ? ''
              : `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${connectChannelId}/users/new`
          }
          size="sm"
          type="button"
          colorScheme={!hasPermission('connect_users_edit') ? 'secondary' : 'primary'}
          isDisabled={!hasPermission('connect_users_edit')}
        >
          ADD
        </Button>
      )}
    </Box>
  </Row>
)

interface ConnectUsersProps {
  connectCustomerId: number
  connectChannelId: number
}

const ConnectUsers: React.FC<ConnectUsersProps> = ({ connectCustomerId, connectChannelId }) => {
  let connectUsersQuery = useConnectUsers(getEnvironment(), connectChannelId)
  let connectUsers = connectUsersQuery?.data

  if (queries.areAnyLoading(connectUsersQuery)) return <Placeholders.LoadingState />
  if (queries.areAnyFailed(connectUsersQuery)) return <Placeholders.FailedState />

  // relative to connect/:env/customers/:connectCustomerId/channels/:connectChannelId/users??
  return (
    <FlexRouter>
      <AuthRoute
        path="/:connectUserId/edit"
        as={UserForm}
        connectUsers={connectUsers}
        header={
          <ConnectUsersHeader
            showAdd={false}
            connectCustomerId={connectCustomerId}
            connectChannelId={connectChannelId}
          />
        }
        connectCustomerId={connectCustomerId}
        connectChannelId={connectChannelId}
      />
      <AuthRoute
        path="/new"
        as={UserForm}
        connectUsers={null}
        header={
          <ConnectUsersHeader
            showAdd={false}
            connectCustomerId={connectCustomerId}
            connectChannelId={connectChannelId}
          />
        }
        connectCustomerId={connectCustomerId}
        connectChannelId={connectChannelId}
      />
      <AuthRoute
        default
        as={ConnectUsersTable}
        connectUsers={connectUsers}
        permissions={{
          edit: 'connect_users_edit',
        }}
        header={
          <ConnectUsersHeader
            showAdd={true}
            connectCustomerId={connectCustomerId}
            connectChannelId={connectChannelId}
          />
        }
        connectCustomerId={connectCustomerId}
        connectChannelId={connectChannelId}
      />
    </FlexRouter>
  )
}

export default ConnectUsers

const ConnectUsersTable = ({
  connectUsers,
  permissions,
  header,
  connectCustomerId,
  connectChannelId,
}) => {
  let columns = React.useMemo(
    () => [
      {
        id: 'id',
        accessor: 'id',
        Header: 'ID',
        disableSortBy: true,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        disableSortBy: false,
      },
      {
        id: 'email',
        accessor: 'email',
        Header: 'Email',
        disableSortBy: true,
      },
      {
        id: 'bushel_id',
        accessor: 'bushel_id',
        Header: 'Bushel ID',
        disableSortBy: true,
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let connectUser = row.original

          return (
            <Row justifyContent="flex-end">
              <Button
                as={Link}
                to={`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${connectChannelId}/users/${
                  connectUser.id
                }/edit`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Edit Connect User"
                isDisabled={!hasPermission(permissions.edit)}
              >
                EDIT
              </Button>
            </Row>
          )
        },
      },
    ],
    [permissions.edit, connectCustomerId, connectChannelId]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: connectUsers,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  return (
    <>
      {header}

      {connectUsers.length === 0 ? (
        <Placeholders.EmptyState message="No users found" />
      ) : (
        <Box bg="white" pt={3}>
          <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
            <Table2.Header headerGroups={headerGroups} />
            <Table2.Body
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2>
        </Box>
      )}
    </>
  )
}
